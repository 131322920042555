import * as React from 'react';
import { Box, Typography } from '@mui/material';
import './FillInBlanks2.scss';
import { useState } from 'react';
import AudioBox from '../../../components/Audio/Audio';
import { handleFillAnswers } from '../../../utils/handleInputAnswers';
import { useSelector } from 'react-redux';
import { OfflinePin } from '@mui/icons-material';

function FillInBlanks2({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);

  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  return (
    <>
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <div id="fill-in-blanks-2-wrapper">
        {ex.questions.map((question) => {
          let contentItems = question.content.split('_');
          if (contentItems[0] === '') contentItems = question.content.split('_').slice(1);

          return (
            <div
              key={question.id}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginBottom: '4rem',
              }}
            >
              <Typography
                variant="body"
                marginRight="1.2rem"
                width="2.8rem"
                height="2.8rem"
                sx={{
                  backgroundColor: '#000',
                  color: '#fff',
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                }}
              >
                {question.ordinalNumber}
              </Typography>
              {question.content.split('_')[0] === ''
                ? contentItems.map((item, index) => (
                    <Box
                      key={index}
                      sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1.2rem' }}
                    >
                      <input
                        disabled={isSubmitted}
                        type="text"
                        className={`input ${
                          isSubmitted
                            ? answerList?.some(
                                (answer) =>
                                  answer.questionId === question.id &&
                                  JSON.parse(answer.result)[index] === 'true',
                              )
                              ? 'true'
                              : 'false'
                            : ''
                        }`}
                        value={
                          isSubmitted
                            ? (() => {
                                const answer = answerList?.find(
                                  (answer) => answer.questionId === question.id,
                                )?.answer;
                                return answer ? JSON.parse(answer)?.[index] || '' : '';
                              })()
                            : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                index
                              ] || ''
                        }
                        onChange={(e) =>
                          handleFillAnswers(e, ex.exerciseType, question.id, index, setAnswerList)
                        }
                      />
                      <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                        {item}
                      </Typography>
                    </Box>
                  ))
                : contentItems.map((item, index) => {
                    if (index !== contentItems.length - 1)
                      return (
                        <Box
                          key={index}
                          sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1.2rem' }}
                        >
                          <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                            {item}
                          </Typography>
                          <input
                            disabled={isSubmitted}
                            type="text"
                            className={`input ${
                              isSubmitted
                                ? answerList?.some(
                                    (answer) =>
                                      answer.questionId === question.id &&
                                      JSON.parse(answer.result)[index] === 'true',
                                  )
                                  ? 'true'
                                  : 'false'
                                : ''
                            }`}
                            value={
                              isSubmitted
                                ? (() => {
                                    const answer = answerList?.find(
                                      (answer) => answer.questionId === question.id,
                                    )?.answer;
                                    return answer ? JSON.parse(answer)?.[index] || '' : '';
                                  })()
                                : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                    index
                                  ] || ''
                            }
                            onChange={(e) =>
                              handleFillAnswers(
                                e,
                                ex.exerciseType,
                                question.id,
                                index,
                                setAnswerList,
                              )
                            }
                          />
                        </Box>
                      );
                    else {
                      return (
                        <Box key={index}>
                          <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                            {item}
                          </Typography>
                        </Box>
                      );
                    }
                  })}
            </div>
          );
        })}

        {isSubmitted && (
          <div className="result-wrapper">
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem', color: '#00baf2' }}>
              <OfflinePin sx={{ fontSize: '2.4rem' }} />
              <p style={{ fontWeight: 'bold', fontSize: '1.8rem', textDecoration: 'underline' }}>
                Đáp án:
              </p>
            </div>
            {ex.questions.map((question) => {
              const answerList = question.answer.split(',');
              return (
                <div
                  xs={6}
                  md={6}
                  lg={6}
                  key={question.id}
                  style={{ marginTop: '2rem', display: 'flex', alignItems: 'center' }}
                >
                  <Typography
                    variant="body"
                    marginRight="1.2rem"
                    width="2.8rem"
                    height="2.8rem"
                    sx={{
                      backgroundColor: '#000',
                      color: '#fff',
                      fontWeight: 'bold',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%',
                    }}
                  >
                    {question.ordinalNumber}
                  </Typography>
                  {answerList.map((item, index) => (
                    <Typography variant="body" key={index}>
                      {item}
                      {index !== answerList.length - 1 && '，'}
                    </Typography>
                  ))}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default FillInBlanks2;
