import React, { useCallback } from 'react';
import { Box, Tooltip, Button, Divider, Stack, Typography, IconButton } from '@mui/material';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import skillServices from '../../services/skillServices';
import './Exercise.scss';
import StyledButton from '../../components/StyledButton/StyledButton';
import Choose1 from './Choose1/Choose1';
import ChooseImage from './ChooseImage/ChooseImage';
import ComboBox1 from './ComboBox1/ComboBox1';
import TrueFalse from './TrueFalse/TrueFalse';
import DragToDrop from './DragToDrop/DragToDrop';
import FillInBlanksConversation from './FillInBlanksConversation/FillInBlanksConversation';
import NewWords from './NewWords/NewWords';
import ChooseMultiple from './ChooseMultiple/ChooseMultiple';
import ListenAndRead from './ListenAndRead/ListenAndRead';
import Choose from './Choose/Choose';
import Match from './Match/Match';
import FillInTheBlanks from './FillInTheBlanks/FillInTheBlanks';
import FillInNumber from './FillInNumber/FillInNumber';
import FillInTones from './FillInTones/FillInTones';
import SameOrNot from './SameOrNot/SameOrNot';
import TrueFalse2 from './TrueFalse2/TrueFalse2';
import TrueFalse3 from './TrueFalse3/TrueFalse3';
import TrueFalse4 from './TrueFalse4/TrueFalse4';
import Skill from '../BookDetail/Skill';
import partServices from '../../services/partServices';
import topicServices from '../../services/topicServices';
import exerciseServices from '../../services/exerciseServices';
import questionServices from '../../services/questionServices';
import bookServices from '../../services/bookServices';
import ComboBox2 from './ComboBox2/ComboBox2';
import ComboBox3 from './ComboBox3/ComboBox3';
import ComboBox4 from './ComboBox4/ComboBox4';
import Choose2 from './Choose2/Choose2';
import Group from './Group/Group';
import FillInBlanks2 from './FillInBlanks2/FillInBlanks2';
import slugUrl from '../../utils/slugUrl';
import { BarLoader } from 'react-spinners';
import ResultPopup from '../../components/ResultPopup/ResultPopup';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSubmitted, setUserAnswer } from '../../redux/userAnswerSlice';
import userServices from '../../services/userServices';
import useAnswerList from '../../data/useAnswerList';

function Exercise() {
  const location = useLocation();
  let path = location.pathname;
  path = path.split('/')[1] + '/' + path.split('/')[2];
  const navigate = useNavigate();
  let { bookId } = useParams();
  let splitBookId = bookId.split('-');
  splitBookId = splitBookId[splitBookId.length - 1].split('b');
  bookId = splitBookId[splitBookId.length - 1];

  let { topicId } = useParams();
  let splitTopicId = topicId.split('-');
  splitTopicId = splitTopicId[splitTopicId.length - 1].split('t');
  topicId = splitTopicId[splitTopicId.length - 1];

  let { partId } = useParams();
  let splitId = partId.split('-');
  splitId = splitId[splitId.length - 1].split('p');
  partId = splitId[splitId.length - 1];

  const [currentPart, setCurrentPart] = React.useState(null);
  const [parts, setParts] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [book, setBook] = React.useState(null);
  const [topics, setTopics] = React.useState([]);
  const [currentTopic, setCurrentTopic] = React.useState('');
  const [availableExercise, setAvailableExercise] = React.useState([]);
  const [currentExercise, setCurrentExercise] = React.useState(availableExercise?.[0] || null);

  const [totalQuestions, setTotalQuestions] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [warning, setWarning] = React.useState({
    open: false,
    content: '',
    type: '',
  });

  const [answerList, setAnswerList] = React.useState([]);
  const [result, setResult] = React.useState();
  const user = useSelector((state) => state.user).user;
  const userAnswer = useSelector((state) => state.userAnswer.userAnswer);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  const { data, loading, error, mutate } = useAnswerList(user?.id, partId);
  const disabledSubmitButton = !isSubmitted && answerList.length === 0;

  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const bookData = await bookServices.getById(bookId);
        setBook({ ...bookData, slug: slugUrl(bookData.name) });

        const topics = await topicServices.getByBookId(bookId);
        setTopics(topics.map((item) => ({ ...item, slug: slugUrl(item.name) })));

        const topicData = await topicServices.getById(topicId);
        setCurrentTopic({ ...topicData, slug: slugUrl(topicData.name) });

        let partList = await Promise.all(
          topics.map(async (item) => {
            const part = await partServices.getByTopicId(item.id);
            return part;
          }),
        );
        partList = partList.flat().map((item) => ({ ...item, slug: slugUrl(item?.name) }));
        setParts(partList);

        const partData = await partServices.getById(partId);
        setCurrentPart(partData);

        const skillsData = await skillServices.getByPartId(partId);
        setSkills(skillsData);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [bookId, topicId, partId]);

  const toNextPart = () => {
    if (parseInt(partId) !== parts[parts?.length - 1]?.id) {
      setCurrentExercise(null);
      const currentIndex = parts.findIndex((item) => item.id === currentPart.id);
      const topicId = parts.find((part) => part?.id === parseInt(partId) + 1).topicId;
      const nextTopic = topics.find((item) => item.id === topicId);
      navigate(
        `/${path}/${book?.slug}-b${book.id}/${nextTopic?.slug}-t${topicId}/${
          parts[currentIndex + 1]?.slug
        }-p${parseInt(partId) + 1}`,
      );
    }
    if (warning.open) setWarning((pre) => ({ ...pre, open: false }));
    setAnswerList([]);
    dispatch(setUserAnswer([]));
  };
  const handleNextPart = () => {
    if (!isSubmitted && (answerList.length > 0 || userAnswer.length > 0)) {
      setWarning({
        open: true,
        content:
          '<p>Nếu chuyển part khi chưa nhấn <span style="font-weight:bold;font-style:italic;">Nộp bài</span>, những câu hỏi bạn đã hoàn thành sẽ không được lưu.</p>',
        type: 'NextPart',
      });
    } else {
      toNextPart();
    }
  };
  const toPreviousPart = () => {
    if (parseInt(partId) !== parts?.[0]?.id) {
      setCurrentExercise(null);
      const currentIndex = parts.findIndex((item) => item.id === currentPart.id);
      const topicId = parts.find((part) => part?.id === parseInt(partId) - 1).topicId;
      const nextTopic = topics.find((item) => item.id === topicId);
      navigate(
        `/${path}/${book?.slug}-b${book.id}/${nextTopic?.slug}-t${topicId}/${
          parts[currentIndex + 1]?.slug
        }-p${parseInt(partId) - 1}`,
      );
    }
    if (warning.open) setWarning((pre) => ({ ...pre, open: false }));
    setAnswerList([]);
    dispatch(setUserAnswer([]));
  };
  const handlePreviousPart = () => {
    if (!isSubmitted && (answerList.length > 0 || userAnswer.length > 0)) {
      setWarning({
        open: true,
        content:
          '<p>Nếu chuyển part khi chưa nhấn <span style="font-weight:bold;font-style:italic;">Nộp bài</span>, những câu hỏi bạn đã hoàn thành sẽ không được lưu.</p>',
        type: 'PreviousPart',
      });
    } else {
      toPreviousPart();
    }
  };

  const handleSubmit = async () => {
    if (!!user) {
      let updatedAnswerList = [];
      if (!!userAnswer) updatedAnswerList = [...answerList, ...userAnswer];
      else updatedAnswerList = [...answerList];

      const submitExercise = await userServices.submitExercise(
        {
          answerList: updatedAnswerList,
          partId: parseInt(partId),
        },
        user?.id,
      );
      if (submitExercise) {
        setOpen(true);
        setAnswerList([]);
        mutate();
        dispatch(setIsSubmitted(true));
      }
    } else {
      setWarning({
        open: true,
        content:
          'Bạn chưa đăng nhập. Hãy đăng nhập hoặc tạo tài khoản để lưu lại kết quả luyện tập của mình nhé!',
        type: 'SubmitExercises',
      });
    }
  };

  const handleRedoExercises = () => {
    setWarning({
      open: true,
      content: `<p>Chọn <span style="font-weight:bold;font-style:italic;">Làm lại</span> nghĩa là các kết quả trước đó của part <span style="font-weight:bold;font-style:italic;">${currentPart?.name}</span> mà bạn đã làm sẽ được xóa và không thể khôi phục.</p>`,
      type: 'Redo',
    });
  };

  const handleChooseRedoExercises = async () => {
    const redoExercises = await userServices.redoExercises(user?.id, partId);
    if (redoExercises) {
      dispatch(setUserAnswer([]));
      mutate();
      setAnswerList([]);
      setWarning((pre) => ({ ...pre, open: false }));
      window.location.reload();
    }
  };

  React.useEffect(() => {
    const fetchExercises = async () => {
      let exercisesData = [];
      let totalQuestion = 0;

      if (skills.length > 0) {
        const skillPromises = skills.map(async (skill) => {
          const exercises = await exerciseServices.getBySkillId(skill.id);
          const exercisePromises = exercises.map(async (exercise) => {
            try {
              const questions = await questionServices.getByExerciseId(exercise.id);
              totalQuestion += questions.length;
              return {
                ...exercise,
                questions,
              };
            } catch (err) {
              console.log(err);
              return null;
            }
          });
          return Promise.all(exercisePromises);
        });

        const allExercises = await Promise.all(skillPromises);
        exercisesData = allExercises.flat().filter(Boolean);
      } else {
        const exercises = await exerciseServices.getByPartId(partId);
        const exercisePromises = exercises.map(async (exercise) => {
          try {
            const questions = await questionServices.getByExerciseId(exercise.id);
            totalQuestion += questions.length;
            return {
              ...exercise,
              questions,
            };
          } catch (err) {
            console.log(err);
            return null;
          }
        });

        exercisesData = (await Promise.all(exercisePromises)).filter(Boolean);
      }

      setAvailableExercise(
        exercisesData.map((item, i) => ({
          ...item,
          isLast: i === exercisesData.length - 1,
        })),
      );
      setCurrentExercise(exercisesData[0]);
      setTotalQuestions(totalQuestion);
    };

    fetchExercises();
  }, [skills, partId]);

  const getResult = async (id, partId) => {
    try {
      const response = await userServices.getResultByPartId(id, partId);
      setResult(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleViewDetail = () => {
    setCurrentExercise(availableExercise?.[0]);
    setOpen(false);
  };

  React.useEffect(() => {
    if (data?.length > 0 && !!user) {
      setAnswerList(data);
      dispatch(setIsSubmitted(true));
    } else {
      dispatch(setIsSubmitted(false));
    }
  }, [data, isSubmitted, answerList]);

  React.useEffect(() => {
    getResult(user?.id, partId);
  }, [user?.id, partId, isSubmitted]);

  const handleNext = () => {
    setCurrentExercise(
      availableExercise[
        availableExercise?.findIndex((item) => item.id === currentExercise?.id) + 1
      ],
    );
  };

  const handleChooseAnswer = (ex) => {
    setCurrentExercise(ex);
  };

  return (
    <div id="lesson-exercise-wrapper">
      <Link to={`/${path}/${book?.slug}-b${book?.id}`} className="back-link">
        VỀ TRANG CHỦ ĐỀ
      </Link>
      <Box className="lesson-exercise-container">
        <Box
          sx={{
            width: '100%',
            backgroundColor: '#ffeb3b',
            minHeight: '3.2rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          className="part-name"
        >
          {/* Previous */}
          <IconButton
            variant="text"
            sx={{
              fontSize: '1.2rem',
              color: '#000',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#fff59d',
              },
            }}
            disabled={parseInt(partId) === parts?.[0]?.id}
            onClick={handlePreviousPart}
          >
            <ArrowBackOutlinedIcon fontSize="large" />
          </IconButton>

          {/* Breadcrumbs */}
          <div className="breadcrumb" sx={{ fontSize: '1.4rem' }}>
            <Link
              className="breadcrumb-link"
              key="1"
              style={{ color: '#000', textDecoration: 'none' }}
              to={`/${path}/${book?.slug}-b${book?.id}`}
            >
              <Tooltip title={book?.name.split(',').join(' - ')} placement="top">
                <Box className="book-link" marginRight="0.7rem">
                  <p className="breadcrumb-text">{book?.name.split(',').join(' - ')}</p>
                </Box>
              </Tooltip>
            </Link>
            <Link
              className="breadcrumb-link"
              key="2"
              style={{ color: '#000', textDecoration: 'none' }}
              to={`/${path}/${book?.slug}-b${book?.id}?t=${topicId}`}
            >
              <Tooltip title={currentTopic?.name} placement="top">
                <Box className="topic-link" marginRight="0.7rem">
                  <p className="breadcrumb-text">{currentTopic?.name}</p>
                </Box>
              </Tooltip>
            </Link>
            <Link
              className="breadcrumb-link"
              key="3"
              style={{
                color: '#000',
                fontWeight: 'bold',
                textDecoration: 'none',
                cursor: 'auto',
              }}
            >
              <Tooltip title={currentPart?.name} placement="top">
                <Box className="part-link">
                  <p className="breadcrumb-text">{currentPart?.name}</p>
                </Box>
              </Tooltip>
            </Link>
          </div>

          {/* Next */}
          <IconButton
            variant="text"
            sx={{
              fontSize: '1.2rem',
              color: '#000',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#fff59d',
              },
            }}
            disabled={parseInt(partId) === parts[parts?.length - 1]?.id}
            onClick={handleNextPart}
          >
            <ArrowForwardOutlinedIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box className="body">
          <Stack className="lesson-exercise-list">
            {/* Skill */}
            <Box className="skill-list">
              {skills?.length > 0 ? (
                skills.map(
                  (skill, index) =>
                    !skill.deleted && (
                      <Skill
                        exercises={availableExercise}
                        key={skill?.id}
                        skill={skill}
                        currentExercise={currentExercise}
                        handleChooseAnswer={handleChooseAnswer}
                      />
                    ),
                )
              ) : (
                <Box
                  className="exercise-list"
                  sx={{
                    direction: 'row',
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                    padding: '1.2rem 1.2rem 0 1.2rem',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                  }}
                >
                  {availableExercise?.length > 0 &&
                    availableExercise.map((ex, index) => (
                      <Button
                        key={ex.id}
                        variant="contained"
                        sx={{
                          fontSize: '1.6rem',
                          backgroundColor: '#ffeb3b',
                          color: '#000',
                          boxShadow: 'none',
                          '&:hover': {
                            backgroundColor: '#fbc02d',
                          },
                          marginBottom: '1.2rem',
                          marginLeft: '0.4rem',
                        }}
                        onClick={() => setCurrentExercise(ex)}
                        className={`exercise-item ${currentExercise === ex ? 'selected' : ''}`}
                      >
                        {/* Số thự tự bài tập thuộc part cột trái */}
                        {ex.ordinalNumber}
                      </Button>
                    ))}
                </Box>
              )}
            </Box>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ padding: '2rem' }}
            >
              <Typography style={{ fontSize: '1.4rem' }}>
                Đã làm{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {result?.trueAnswerQuantity}/{totalQuestions}
                </span>{' '}
                câu
              </Typography>
              <Button
                variant="contained"
                disabled={disabledSubmitButton}
                sx={{
                  fontSize: '1.6rem',
                  backgroundColor: isSubmitted ? '#fff' : '#0ac7f1',
                  color: isSubmitted ? '#00baf2' : '#fff',
                  boxShadow: 'none',
                  fontWeight: '600',
                  '&:hover': {
                    backgroundColor: isSubmitted ? '#fff' : '#2dd8fd',
                  },
                  marginTop: '1.2rem',
                  width: '100%',
                  border: isSubmitted ? '2px solid #0ac7f1' : 'none',
                }}
                onClick={() => {
                  if (isSubmitted) setOpen(true);
                  else handleSubmit();
                }}
              >
                {isSubmitted ? 'Xem kết quả' : 'Nộp bài'}
              </Button>
              {isSubmitted && (
                <Button
                  variant="contained"
                  sx={{
                    fontSize: '1.6rem',
                    backgroundColor: 'transparent',
                    border: '2px solid #f57f17',
                    color: '#f57f17',
                    boxShadow: 'none',
                    fontWeight: '600',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    marginTop: '1.2rem',
                    width: '100%',
                  }}
                  onClick={() => handleRedoExercises()}
                >
                  Làm lại
                </Button>
              )}
            </Stack>
          </Stack>
          <Divider orientation="vertical" className="divider" />

          <Box className="lesson-exercise-content">
            {!!currentExercise ? (
              <Box sx={{ marginBottom: '1.2rem' }}>
                {/* <Box className="exercise-number"> */}
                <div style={{ marginBottom: '-0.8rem' }}>
                  <Typography variant="body" fontWeight="bold" sx={{ textDecoration: 'underline' }}>
                    {currentExercise?.ordinalNumber.includes('Text') ||
                    currentExercise?.ordinalNumber.includes('第')
                      ? currentExercise.ordinalNumber
                      : `Exercise ${currentExercise.ordinalNumber}`}
                  </Typography>
                </div>
                {/* </Box> */}
                <br></br>
                <Typography variant="body">{currentExercise?.description}</Typography>
                <Box sx={{ marginBottom: '3.2rem' }}>
                  {currentExercise?.exerciseType.includes('Listen and read') ? (
                    <ListenAndRead ex={currentExercise} />
                  ) : currentExercise?.exerciseType === 'Match' ? (
                    <Match
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'Choose' ? (
                    <Choose
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'Fill in the blanks' ? (
                    <FillInTheBlanks
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'FillInBlanks2' ? (
                    <FillInBlanks2
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'Fill in tones' ? (
                    <FillInTones
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'Fill in number' ? (
                    <FillInNumber
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'Same or not' ? (
                    <SameOrNot
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'Choose1' ? (
                    <Choose1
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'Choose2' ? (
                    <Choose2
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'ChooseImage' ? (
                    <ChooseImage
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'ComboBox1' ? (
                    <ComboBox1
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'ComboBox2' ? (
                    <ComboBox2
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'ComboBox3' ? (
                    <ComboBox3
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'ComboBox4' ? (
                    <ComboBox4
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'TrueFalse' ? (
                    <TrueFalse
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'TrueFalse2' ? (
                    <TrueFalse2
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'TrueFalse3' ? (
                    <TrueFalse3
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'TrueFalse4' ? (
                    <TrueFalse4
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'DragToDrop' ? (
                    <DragToDrop
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'Group' ? (
                    <Group
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'FillInBlanksConversation' ? (
                    <FillInBlanksConversation
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : currentExercise?.exerciseType === 'NewWords' ? (
                    <NewWords ex={currentExercise} />
                  ) : currentExercise?.exerciseType === 'ChooseMultiple' ? (
                    <ChooseMultiple
                      ex={currentExercise}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                    />
                  ) : availableExercise?.length === 0 ? (
                    'Phần này không có bài tập nào bạn nhé!'
                  ) : (
                    ''
                  )}
                </Box>
                <Stack direction="row" className="button">
                  {!isSubmitted ? (
                    availableExercise?.some(
                      (ex) => ex?.id === currentExercise?.id && !!ex?.isLast,
                    ) ? (
                      <Button
                        variant="contained"
                        className="submit-btn"
                        disabled={disabledSubmitButton}
                        sx={{
                          fontSize: '1.6rem',
                          backgroundColor: '#0ac7f1',
                          color: '#fff',
                          boxShadow: 'none',
                          fontWeight: '600',
                          '&:hover': {
                            backgroundColor: '#2dd8fd',
                          },
                          marginTop: '1.2rem',
                          width: '100%',
                        }}
                        onClick={() => handleSubmit()}
                      >
                        Nộp bài
                      </Button>
                    ) : (
                      <StyledButton
                        onClick={() => handleNext()}
                        disabled={isSubmitted}
                        content={'Bài tiếp theo'}
                      />
                    )
                  ) : (
                    ''
                  )}
                </Stack>
              </Box>
            ) : (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BarLoader color="#ffeb3b" width={200} speedMultiplier={1.5} />
                <Typography sx={{ fontSize: '1.4rem', mt: '1.2rem' }} color="rgba(0, 0, 0, 0.7)">
                  Loading...
                </Typography>
              </div>
            )}
          </Box>
        </Box>
      </Box>
      <ResultPopup
        open={open}
        setOpen={setOpen}
        result={result}
        handleViewDetail={handleViewDetail}
        currentPartName={currentPart?.name}
      />
      <WarningPopup
        warning={warning}
        setWarning={setWarning}
        handleChooseRedoExercises={handleChooseRedoExercises}
        toNextPart={toNextPart}
        toPreviousPart={toPreviousPart}
      />
    </div>
  );
}

export default Exercise;
