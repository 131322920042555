import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import './TrueFalse.scss';
import { useState } from 'react';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';

function TrueFalse({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  const handleSelectAnswer = (questionId, answerItem) => {
    !isSubmitted &&
      setAnswerList((prevState) => {
        const updatedAnswers = prevState.map((answer) => {
          if (answer.id === questionId) {
            return { id: questionId, answer: answerItem, exerciseType: ex.exerciseType };
          }
          return answer;
        });
        const isNewAnswer = !prevState.some((answer) => answer.id === questionId);
        if (isNewAnswer) {
          updatedAnswers.push({
            id: questionId,
            answer: answerItem,
            exerciseType: ex.exerciseType,
          });
        }
        return updatedAnswers;
      });
  };

  return (
    <>
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div id="true-false-wrapper">
          <Stack className="true-false-text-wrapper" direction="row">
            <Stack direction="row" className="true-false-text">
              <Typography variant="body" className="true-text">
                True
              </Typography>
              <Typography variant="body">False</Typography>
            </Stack>
          </Stack>
          {/* Question */}
          <Box>
            {ex.questions.length > 0 &&
              ex.questions.map((question) => {
                let contentList = question.content.split('(');
                return (
                  <Stack direction="row" className="question" key={question.id}>
                    <Box marginTop="0.3rem" marginBottom="0.4rem" className="image">
                      <img src={question.images} alt="question" className="image-item" />
                    </Box>
                    <Stack direction="column" className="content">
                      <Typography variant="body" fontSize="1.2rem">
                        {`(${contentList[1]}`}
                      </Typography>
                      <Typography variant="body">{`${contentList[0]}`}</Typography>
                    </Stack>
                    <Stack direction="row" className="box-wrapper">
                      <Box
                        className={`true-box ${
                          isSubmitted
                            ? answerList.some(
                                (answer) =>
                                  answer.questionId === question.id &&
                                  JSON.parse(answer.answer) === 'true',
                              )
                              ? 'clicked'
                              : ''
                            : answerList.some(
                                (answer) => answer.id === question.id && answer.answer === 'true',
                              )
                            ? 'clicked'
                            : ''
                        }`}
                        onClick={() => handleSelectAnswer(question.id, 'true')}
                      />
                      <Box
                        className={`false-box ${
                          isSubmitted
                            ? answerList.some(
                                (answer) =>
                                  answer.questionId === question.id &&
                                  JSON.parse(answer.answer) === 'false',
                              )
                              ? 'clicked'
                              : ''
                            : answerList.some(
                                (answer) => answer.id === question.id && answer.answer === 'false',
                              )
                            ? 'clicked'
                            : ''
                        }`}
                        onClick={() => handleSelectAnswer(question.id, 'false')}
                      />
                    </Stack>
                    {isSubmitted &&
                      (answerList.some(
                        (answer) => answer.questionId === question.id && answer.result === '1',
                      ) ? (
                        <Box className="answer true">✔</Box>
                      ) : (
                        <Box className="answer false">✘</Box>
                      ))}
                  </Stack>
                );
              })}
          </Box>
        </div>
      </div>
    </>
  );
}

export default TrueFalse;
