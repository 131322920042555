import * as React from 'react';
import './TrueFalse4.scss';
import { useState } from 'react';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';

function TrueFalse4({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);

  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  const handleSelectAnswer = (questionId, answerItem) => {
    !isSubmitted &&
      setAnswerList((prevState) => {
        const updatedAnswers = prevState.map((answer) => {
          if (answer.id === questionId) {
            return { id: questionId, answer: answerItem, exerciseType: ex.exerciseType };
          }
          return answer;
        });
        const isNewAnswer = !prevState.some((answer) => answer.id === questionId);
        if (isNewAnswer) {
          updatedAnswers.push({
            id: questionId,
            answer: answerItem,
            exerciseType: ex.exerciseType,
          });
        }
        return updatedAnswers;
      });
  };

  return (
    // Dạng lấy ảnh làm trung tâm # TrueFalse2 lấy text làm trung tâm
    <>
      {ex.record && <AudioBox exeAudio={currentAudio} />}

      <div id="true-false-4-wrapper">
        {/* Question */}
        <table className="table-container">
          <tbody>
            {ex.questions.length > 0 &&
              ex.questions.map((question) => {
                return (
                  <tr className="question" key={question.id}>
                    <td className="ordinal-number">{question.ordinalNumber}.</td>
                    <td className="image">
                      <img src={question.images} alt="question" className="image-item" />
                      <p className="content" style={{ opacity: isSubmitted ? 1 : 0 }}>
                        {question.content}
                      </p>
                    </td>
                    <td className="box-wrapper">
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <p
                          className={`true-box ${
                            isSubmitted
                              ? answerList.some(
                                  (answer) =>
                                    answer.questionId === question.id &&
                                    JSON.parse(answer.answer) === 'true',
                                )
                                ? 'clicked'
                                : ''
                              : answerList.some(
                                  (answer) => answer.id === question.id && answer.answer === 'true',
                                )
                              ? 'clicked'
                              : ''
                          }`}
                          onClick={() => handleSelectAnswer(question.id, 'true')}
                        />
                        <p
                          className={`false-box ${
                            isSubmitted
                              ? answerList.some(
                                  (answer) =>
                                    answer.questionId === question.id &&
                                    JSON.parse(answer.answer) === 'false',
                                )
                                ? 'clicked'
                                : ''
                              : answerList.some(
                                  (answer) =>
                                    answer.id === question.id && answer.answer === 'false',
                                )
                              ? 'clicked'
                              : ''
                          }`}
                          onClick={() => handleSelectAnswer(question.id, 'false')}
                        />
                      </div>
                    </td>
                    {isSubmitted &&
                      (answerList.some(
                        (answer) => answer.questionId === question.id && answer.result === '1',
                      ) ? (
                        <td className="answer true">✔</td>
                      ) : (
                        <td className="answer false">✘</td>
                      ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TrueFalse4;
