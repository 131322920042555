import * as React from 'react';
import { useState } from 'react';
import { Stack, Typography, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './ComboBox4.scss';
import answerServices from '../../../services/answerServices';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';
import { OfflinePin } from '@mui/icons-material';

function ComboBox4({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = useState(ex.record ? ex.record : null);
  const [optionList, setOptionList] = useState([]);
  const [questionWithAnswer, setQuestionWithAnswer] = React.useState();
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  const GetExerciseWithAnswer = () => {
    const questionList = [...ex.questions];
    const questionsWithAnswer = questionList.map((ques) => {
      const foundItem = optionList.find((item) => item.questionId === ques.id);
      return {
        ...ques,
        answer: foundItem ? foundItem.content : '', // Use the content of the found item or an empty string if not found
      };
    });
    setQuestionWithAnswer(questionsWithAnswer);
  };

  React.useEffect(() => {
    if (optionList.length > 0) {
      GetExerciseWithAnswer();
    }
  }, [optionList]);
  React.useEffect(() => {
    setQuestionWithAnswer(null);
    setCurrentAudio(ex.record ? ex.record : null);
    answerServices
      .getByExerciseId(ex.id)
      .then((data) => setOptionList(data))
      .catch((err) => console.log(err));
  }, [ex]);

  const handleSelectAnswer = (questionId, answerItem) => {
    !isSubmitted &&
      setAnswerList((prevState) => {
        if (prevState.length <= 0) {
          return [
            {
              id: questionId,
              answer: answerItem,
              exerciseType: ex.exerciseType,
            },
          ];
        } else {
          const existedId = prevState.findIndex((answer) => answer.id === questionId);
          let updatedAnswers = prevState;

          if (existedId !== -1)
            updatedAnswers[existedId] = {
              id: questionId,
              answer: answerItem,
              exerciseType: ex.exerciseType,
            };
          else
            updatedAnswers.push({
              id: questionId,
              answer: answerItem,
              exerciseType: ex.exerciseType,
            });
          return [...updatedAnswers];
        }
      });
  };

  return (
    <>
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <div id="combobox4-wrapper">
        {ex?.questions?.length > 0 &&
          ex?.questions?.map((question) => (
            <div style={{ display: 'flex' }} key={question.id}>
              <Typography variant="body" marginRight="2rem">
                {`(${question.ordinalNumber})`}
              </Typography>
              <Stack
                direction="row"
                className="question"
                key={question.id}
                sx={{ marginBottom: '3rem' }}
              >
                <img className="image-answer" src={question.images} alt="questtion" />

                <div style={{ display: 'flex' }}>
                  <FormControl
                    className={`combo-box
                      ${
                        isSubmitted
                          ? answerList.some(
                              (answer) =>
                                answer.result === '1' && answer.questionId === question.id,
                            )
                            ? 'true'
                            : 'false'
                          : ''
                      }
                      `}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      sx={{ fontSize: '1.6rem' }}
                      value={
                        isSubmitted
                          ? parseInt(
                              answerList.find((answer) => answer.questionId === question.id)
                                ?.answer,
                            ) || ''
                          : answerList.find((answer) => answer.id === question.id)?.answer || ''
                      }
                      onChange={(e) => handleSelectAnswer(question.id, e.target.value)}
                    >
                      {optionList?.map((option, index) => (
                        <MenuItem sx={{ fontSize: '1.6rem' }} key={index} value={option.questionId}>
                          {option.content}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Stack>
            </div>
          ))}

        {isSubmitted && (
          <div className="result-wrapper">
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem', color: '#00baf2' }}>
              <OfflinePin sx={{ fontSize: '2.4rem' }} />
              <p style={{ fontWeight: 'bold', fontSize: '1.8rem', textDecoration: 'underline' }}>
                Đáp án:
              </p>
            </div>
            <Grid
              container
              style={{ marginTop: '1.6rem', marginLeft: '2.8rem' }}
              className="answer-wrapper"
            >
              {questionWithAnswer?.map((question) => (
                <Grid item xs={6} md={6} lg={6} key={question.id} style={{ marginTop: '1.2rem' }}>
                  <Typography
                    variant="body"
                    marginRight="2rem"
                  >{`(${question.ordinalNumber})`}</Typography>
                  <Typography variant="body">{question.answer}</Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}

export default ComboBox4;
