import * as React from 'react';
import { Grid, Typography, Box, TextField } from '@mui/material';
import './Match.scss';
import AudioBox from '../../../components/Audio/Audio';
import { handleFillAnswers } from '../../../utils/handleInputAnswers';
import { useSelector } from 'react-redux';
import { OfflinePin } from '@mui/icons-material';

function Match({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = React.useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  return (
    <div className="match-wrapper">
      <div style={{ fontSize: '1.4rem', fontStyle: 'italic' }}>
        *Điền vào chỗ trống để nối từ cho đúng.
      </div>
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      {ex.questions.length > 0 &&
        ex.questions.map((question) => {
          let column1 = question.content.split('-')[0];
          let column2 = question.content.split('-')[1];
          return (
            <Box key={question.id} className="question-4-wrapper">
              <Grid container className="question-4" spacing={{ xs: 0, md: 2, lg: 6 }}>
                {ex.questions.length > 1 && (
                  <Grid item xs={12} md={2} lg={2} marginTop="3rem">
                    <Typography variant="body">{`(${question.ordinalNumber})`}</Typography>
                  </Grid>
                )}
                <Grid item xs={3} md={3} lg={3} marginTop="2rem" className="content-column-1">
                  {column1.split(',').map((item, index) => (
                    <Box key={index} marginTop="1rem">
                      <Typography variant="body">{item}</Typography>
                      <br></br>
                    </Box>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={3}
                  md={3}
                  lg={3}
                  margin="2rem 5.6rem 0 -4rem"
                  className="content-column-2"
                >
                  {column2.split(',').map((item, index) => (
                    <Box key={index} marginTop="1rem">
                      <Typography variant="body">{item}</Typography>
                      <br></br>
                    </Box>
                  ))}
                </Grid>
                <Grid item xs={4} md={3} lg={3} marginTop="2rem" className="content-column-3">
                  {column1.split(',').map((item, index) => (
                    <Box key={index} marginTop="1rem">
                      <Typography
                        className={`question-key ${
                          isSubmitted
                            ? answerList?.some(
                                (answer) =>
                                  answer.questionId === question.id &&
                                  JSON.parse(answer.result)[index] === 'true',
                              )
                              ? 'true'
                              : 'false'
                            : ''
                        }`}
                        variant="body"
                      >
                        {item}
                      </Typography>
                      <TextField
                        disabled={isSubmitted}
                        id="standard-basic"
                        variant="standard"
                        sx={{
                          width: '5rem',
                          marginLeft: '0.4rem',
                        }}
                        value={
                          isSubmitted
                            ? (() => {
                                const answer = answerList?.find(
                                  (answer) => answer.questionId === question.id,
                                )?.answer;
                                return answer ? JSON.parse(answer)?.[index] || '' : '';
                              })()
                            : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                index
                              ] || ''
                        }
                        onChange={(e) =>
                          handleFillAnswers(e, ex.exerciseType, question.id, index, setAnswerList)
                        }
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Box>
          );
        })}
      {isSubmitted && (
        <div className="result-wrapper">
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem', color: '#00baf2' }}>
            <OfflinePin sx={{ fontSize: '2.4rem' }} />
            <p style={{ fontWeight: 'bold', fontSize: '1.8rem', textDecoration: 'underline' }}>
              Đáp án:
            </p>
          </div>
          <div
            style={{
              marginTop: '4rem',
              marginLeft: '2rem',
            }}
          >
            {ex.exerciseType === 'Match' &&
              ex.questions.map((question) => {
                let consonants = question.content.split('-')[0].split(',');
                let answerList = question.answer.split(',');
                return (
                  <Grid container spacing={{ xs: 0 }} key={question.id} className="answer-item">
                    {question.ordinalNumber && (
                      <Grid item xs={12} md={2} lg={2} style={{ marginTop: '1.2rem' }}>
                        <Typography
                          variant="body"
                          marginRight="0.8rem"
                        >{`(${question.ordinalNumber})`}</Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} md={10} lg={6} style={{ marginTop: '1.2rem' }}>
                      {answerList.map((item, index) => (
                        <Typography variant="body" marginRight="2rem" key={index}>
                          {consonants[index] + item}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}

export default Match;
