import * as React from 'react';
import { Box, Stack, Typography, Grid } from '@mui/material';
import './FillInBlanksConversation.scss';
import { useState } from 'react';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';
import { handleFillAnswers } from '../../../utils/handleInputAnswers';
import { OfflinePin } from '@mui/icons-material';

function FillInBlanksConversation({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);

  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  return (
    <>
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <div id="fill-in-blanks-conversation-wrapper">
        {ex.questions.map((question) => {
          let wordsVsPinyin = question.content.split('/');
          let contentItems = wordsVsPinyin[0].split('_');
          if (contentItems[0] === '') contentItems = wordsVsPinyin[0].split('_').slice(1);

          if (question.id % 2 !== 0) {
            return (
              <Stack direction="row" key={question.id} className="content-wrapper">
                <img src={question.images} alt="question" className="character" />
                {wordsVsPinyin[0].split('_')[0] === '' ? (
                  <div className={`chat-box ${question.id % 2 !== 0 ? 'odd' : 'even'}`}>
                    <Typography className="subtitle">{`(${wordsVsPinyin[1]})`}</Typography>
                    {contentItems.map((item, index) => (
                      <Box key={index} className="content-item">
                        <input
                          type="text"
                          className={`input ${
                            isSubmitted
                              ? answerList?.some(
                                  (answer) =>
                                    answer.questionId === question.id &&
                                    JSON.parse(answer.result)[index] === 'true',
                                )
                                ? 'true'
                                : 'false'
                              : ''
                          }`}
                          value={
                            isSubmitted
                              ? (() => {
                                  const answer = answerList?.find(
                                    (answer) => answer.questionId === question.id,
                                  )?.answer;
                                  return answer ? JSON.parse(answer)?.[index] || '' : '';
                                })()
                              : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                  index
                                ] || ''
                          }
                          onChange={(e) =>
                            !isSubmitted &&
                            handleFillAnswers(e, ex.exerciseType, question.id, index, setAnswerList)
                          }
                        />
                        <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                          {item}
                        </Typography>
                      </Box>
                    ))}
                  </div>
                ) : (
                  <div className={`chat-box ${question.id % 2 !== 0 ? 'odd' : 'even'}`}>
                    <Typography className="subtitle">{`(${wordsVsPinyin[1]})`}</Typography>
                    {contentItems.map((item, index) => {
                      if (index !== contentItems.length - 1)
                        return (
                          <Box key={index} className="content-item">
                            <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                              {item}
                            </Typography>
                            <input
                              type="text"
                              className={`input ${
                                isSubmitted
                                  ? answerList?.some(
                                      (answer) =>
                                        answer.questionId === question.id &&
                                        JSON.parse(answer.result)[index] === 'true',
                                    )
                                    ? 'true'
                                    : 'false'
                                  : ''
                              }`}
                              value={
                                isSubmitted
                                  ? (() => {
                                      const answer = answerList?.find(
                                        (answer) => answer.questionId === question.id,
                                      )?.answer;
                                      return answer ? JSON.parse(answer)?.[index] || '' : '';
                                    })()
                                  : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                      index
                                    ] || ''
                              }
                              onChange={(e) =>
                                !isSubmitted &&
                                handleFillAnswers(
                                  e,
                                  ex.exerciseType,
                                  question.id,
                                  index,
                                  setAnswerList,
                                )
                              }
                            />
                          </Box>
                        );
                      else {
                        return (
                          <Box key={index} className="content-item">
                            <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                              {item}
                            </Typography>
                          </Box>
                        );
                      }
                    })}
                  </div>
                )}
              </Stack>
            );
          } else {
            return (
              <Stack direction="row" key={question.id} className="content-wrapper">
                {wordsVsPinyin[0].split('_')[0] === '' ? (
                  <div className={`chat-box ${question.id % 2 !== 0 ? 'odd' : 'even'}`}>
                    <Typography className="subtitle">{`(${wordsVsPinyin[1]})`}</Typography>
                    {contentItems.map((item, index) => (
                      <Box key={index} className="content-item">
                        <input
                          type="text"
                          className={`input ${
                            isSubmitted
                              ? answerList?.some(
                                  (answer) =>
                                    answer.questionId === question.id &&
                                    JSON.parse(answer.result)[index] === 'true',
                                )
                                ? 'true'
                                : 'false'
                              : ''
                          }`}
                          value={
                            isSubmitted
                              ? (() => {
                                  const answer = answerList?.find(
                                    (answer) => answer.questionId === question.id,
                                  )?.answer;
                                  return answer ? JSON.parse(answer)?.[index] || '' : '';
                                })()
                              : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                  index
                                ] || ''
                          }
                          onChange={(e) =>
                            !isSubmitted &&
                            handleFillAnswers(e, ex.exerciseType, question.id, index, setAnswerList)
                          }
                        />
                        <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                          {item}
                        </Typography>
                      </Box>
                    ))}
                  </div>
                ) : (
                  <div className={`chat-box ${question.id % 2 !== 0 ? 'odd' : 'even'}`}>
                    <Typography className="subtitle">{`(${wordsVsPinyin[1]})`}</Typography>
                    {contentItems.map((item, index) => {
                      if (index !== contentItems.length - 1)
                        return (
                          <Box key={index} className="content-item">
                            <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                              {item}
                            </Typography>
                            <input
                              type="text"
                              className={`input ${
                                isSubmitted
                                  ? answerList?.some(
                                      (answer) =>
                                        answer.questionId === question.id &&
                                        JSON.parse(answer.result)[index] === 'true',
                                    )
                                    ? 'true'
                                    : 'false'
                                  : ''
                              }`}
                              value={
                                isSubmitted
                                  ? (() => {
                                      const answer = answerList?.find(
                                        (answer) => answer.questionId === question.id,
                                      )?.answer;
                                      return answer ? JSON.parse(answer)?.[index] || '' : '';
                                    })()
                                  : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                      index
                                    ] || ''
                              }
                              onChange={(e) =>
                                !isSubmitted &&
                                handleFillAnswers(
                                  e,
                                  ex.exerciseType,
                                  question.id,
                                  index,
                                  setAnswerList,
                                )
                              }
                            />
                          </Box>
                        );
                      else {
                        return (
                          <Box key={index} className="content-item">
                            <Typography variant="body" sx={{ zIndex: 10, position: 'relative' }}>
                              {item}
                            </Typography>
                          </Box>
                        );
                      }
                    })}
                  </div>
                )}
                <img src={question.images} alt="question" className="character" />
              </Stack>
            );
          }
        })}
        {isSubmitted && (
          <div className="result-wrapper">
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem', color: '#00baf2' }}>
              <OfflinePin sx={{ fontSize: '2.4rem' }} />
              <p style={{ fontWeight: 'bold', fontSize: '1.8rem', textDecoration: 'underline' }}>
                Đáp án:
              </p>
            </div>
            <Grid container style={{ marginTop: '1.6rem', marginLeft: '2.8rem' }}>
              {ex.questions.map((question) => (
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  key={question.id}
                  style={{
                    marginTop: '1.2rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1.2rem',
                  }}
                >
                  <img
                    src={question.images}
                    alt="question"
                    style={{ maxWidth: '4rem', maxHeight: '4rem', marginLeft: '2rem' }}
                  />
                  <Typography variant="body">{question.answer}</Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}

export default FillInBlanksConversation;
