import * as React from 'react';
import { Grid, Typography, Box, TextField } from '@mui/material';
import './FillInTheBlanks.scss';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';
import { handleFillAnswers } from '../../../utils/handleInputAnswers';
import { OfflinePin } from '@mui/icons-material';

function FillInTheBlanks({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = React.useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  return (
    <div className="fill-in-blanks-wrapper">
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <Grid container marginLeft="2.8rem" className="question-5-wrapper">
        {ex.questions.length > 0 &&
          ex.exerciseType === 'Fill in the blanks' &&
          ex.questions.map((question) => {
            let questionItems = question.content.split('_');
            if (questionItems[0] === '') {
              questionItems = question.content.split('_').slice(1);
              return (
                <Grid
                  item
                  marginTop="2rem"
                  xs={6}
                  md={6}
                  lg={6}
                  key={question.id}
                  className="question-5"
                >
                  <Typography
                    variant="body"
                    marginRight="0.8rem"
                  >{`(${question.ordinalNumber})`}</Typography>
                  {questionItems.map((item, index) => (
                    <Box key={index} className="question-key">
                      <TextField
                        value={
                          isSubmitted
                            ? (() => {
                                const answer = answerList?.find(
                                  (answer) => answer.questionId === question.id,
                                )?.answer;
                                return answer ? JSON.parse(answer)?.[index] || '' : '';
                              })()
                            : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                index
                              ] || ''
                        }
                        className={`textfield ${
                          isSubmitted
                            ? answerList?.some(
                                (answer) =>
                                  answer.questionId === question.id &&
                                  JSON.parse(answer.result)?.[index] === 'true',
                              )
                              ? 'true'
                              : 'false'
                            : ''
                        }`}
                        id="standard-basic"
                        variant="standard"
                        sx={{
                          width: '2.8rem',
                          marginLeft: '0.4rem',
                        }}
                        onChange={(e) =>
                          !isSubmitted &&
                          handleFillAnswers(e, ex.exerciseType, question.id, index, setAnswerList)
                        }
                      />
                      <Typography variant="body">{item}</Typography>
                    </Box>
                  ))}
                </Grid>
              );
            } else {
              questionItems.pop();
              return (
                <Grid
                  item
                  marginTop="2rem"
                  xs={6}
                  md={6}
                  lg={6}
                  key={question.id}
                  className="question-5"
                >
                  <Typography
                    variant="body"
                    marginRight="0.8rem"
                  >{`(${question.ordinalNumber})`}</Typography>
                  {questionItems.map((item, index) => (
                    <Box key={index} className="question-key">
                      <Typography variant="body">{item}</Typography>
                      <TextField
                        value={
                          isSubmitted
                            ? (() => {
                                const answer = answerList?.find(
                                  (answer) => answer.questionId === question.id,
                                )?.answer;
                                return answer ? JSON.parse(answer)?.[index] || '' : '';
                              })()
                            : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                index
                              ] || ''
                        }
                        className={`textfield ${
                          isSubmitted
                            ? answerList?.some(
                                (answer) =>
                                  answer.questionId === question.id &&
                                  JSON.parse(answer.result)?.[index] === 'true',
                              )
                              ? 'true'
                              : 'false'
                            : ''
                        }`}
                        id="standard-basic"
                        variant="standard"
                        sx={{
                          width: '2.8rem',
                          marginLeft: '0.4rem',
                        }}
                        onChange={(e) =>
                          !isSubmitted &&
                          handleFillAnswers(e, ex.exerciseType, question.id, index, setAnswerList)
                        }
                      />
                    </Box>
                  ))}
                </Grid>
              );
            }
          })}
      </Grid>
      {isSubmitted && (
        <div className="result-wrapper">
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem', color: '#00baf2' }}>
            <OfflinePin sx={{ fontSize: '2.4rem' }} />
            <p style={{ fontWeight: 'bold', fontSize: '1.8rem', textDecoration: 'underline' }}>
              Đáp án:
            </p>
          </div>
          <Grid container style={{ marginTop: '1.6rem', marginLeft: '2.8rem' }}>
            {ex.exerciseType === 'Fill in the blanks' &&
              ex.questions.map((question) => {
                let contents = question.content.split('_');
                let answers = question.answer.split(',');

                return (
                  <Grid item xs={6} md={6} lg={6} key={question.id} style={{ marginTop: '1.2rem' }}>
                    <Typography
                      variant="body"
                      marginRight="2rem"
                    >{`(${question.ordinalNumber})`}</Typography>
                    <Typography variant="body">
                      {answers.map((item, subIndex) => {
                        if (contents[0] === '') return item + contents[subIndex + 1];
                        else return contents[subIndex] + item;
                      })}
                    </Typography>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default FillInTheBlanks;
