import { getUserStorage } from '../utils/storage';
import { getAccessToken } from '../utils/cookies';

const apiUrl = process.env.REACT_APP_API_URL;
const userServices = {
  getById: async (id) => {
    try {
      const response = await fetch(apiUrl + `/users/${id}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  getCurrentUser: async () => {
    try {
      const userId = getUserStorage().id;
      if (!getAccessToken()) {
        console.error('No access token found. Cannot fetch user.');
        return null;
      }

      const data = await userServices.getById(userId);
      return data;
    } catch (error) {
      console.error('Error fetching the current user', error);
      return null;
    }
  },

  getAll: async () => {
    try {
      const response = await fetch(apiUrl + '/users');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  updateById: async (data) => {
    try {
      const accessToken = getAccessToken();

      const formData = new FormData();
      if (!!data.name) formData.append('name', data.name);
      if (data.avatar instanceof File) {
        formData.append('avatar', data.avatar);
      }

      const response = await fetch(apiUrl + `/users/${data.id}?folder=users`, {
        method: 'PUT',
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  },

  changePassword: async (data) => {
    try {
      const accessToken = getAccessToken();

      const response = await fetch(apiUrl + `/users/change-password/${data.id}`, {
        method: 'PUT',
        // mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      });

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  },

  googleLogin: async (data) => {
    try {
      const response = await fetch(apiUrl + '/auth/google/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  },

  googleSignup: async (data) => {
    try {
      const response = await fetch(apiUrl + '/auth/google/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  },

  forgotPassword: async (data) => {
    try {
      const response = await fetch(apiUrl + '/auth/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  },

  signUp: async (data) => {
    try {
      const response = await fetch(apiUrl + '/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  },

  login: async (data) => {
    try {
      const response = await fetch(apiUrl + '/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(data),
        // mode: 'cors',
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  },
  logout: async (data) => {},

  getAnswerByPartId: async (userId, partId) => {
    const response = await fetch(`${apiUrl}/users/${userId}/get-answer?partId=${partId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch user answer');
    }
    const data = await response.json();
    return data;
  },

  submitExercise: async (data, userId) => {
    try {
      const response = await fetch(apiUrl + `/users/${userId}/submit-exercise`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
    }
  },

  getResultByPartId: async (id, partId) => {
    try {
      const response = await fetch(apiUrl + `/users/${id}/results/${partId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
    }
  },

  redoExercises: async (userId, partId) => {
    try {
      const response = await fetch(apiUrl + `/users/${userId}/redo`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ partId: partId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
    }
  },
};

export default userServices;
