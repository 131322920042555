import * as React from 'react';
import { Grid, Typography, Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import './Choose.scss';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';
function Choose({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = React.useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  const handleChooseAnswers = (e, questionId) => {
    if (!isSubmitted) {
      setAnswerList((prevState) => {
        const updatedAnswers = prevState.map((answer) => {
          if (answer.id === questionId) {
            return { id: questionId, answer: e.target.value, exerciseType: ex.exerciseType };
          }
          return answer;
        });

        const isNewAnswer = !prevState.some((answer) => answer.id === questionId);
        if (isNewAnswer) {
          updatedAnswers.push({
            id: questionId,
            answer: e.target.value,
            exerciseType: ex.exerciseType,
          });
        }
        return updatedAnswers;
      });
    }
  };

  return (
    <div className="choose-wrapper">
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginTop: '2.8rem', width: '100%' }}>
          {ex.questions.length > 0 &&
            ex.exerciseType === 'Choose' &&
            ex.questions.map((question) => {
              let questionContents = question.content.split(',');
              return (
                <Box marginTop="1.2rem" key={question.id} className="question-3-wrapper">
                  <Grid
                    container
                    className="question-3"
                    spacing={{ xs: 1, md: 2, lg: 1 }}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Grid item xs={2} md={2} lg={2}>
                      {`(${question.ordinalNumber})`}
                    </Grid>
                    <Grid item xs={10} md={10} lg={10}>
                      <RadioGroup
                        row
                        onChange={(e) => handleChooseAnswers(e, question.id)}
                        value={
                          isSubmitted
                            ? (() => {
                                const foundItem = answerList.find(
                                  (item) => item.questionId === question.id,
                                );
                                return foundItem ? JSON.parse(foundItem.answer) : '';
                              })()
                            : answerList.find((item) => item.id === question.id)?.answer || ''
                        }
                      >
                        <Grid container>
                          {questionContents.map((item, index) => (
                            <Grid
                              item
                              xs={12 / questionContents.length}
                              md={12 / questionContents.length}
                              lg={12 / questionContents.length}
                              key={index}
                            >
                              <FormControlLabel
                                value={item}
                                control={
                                  <Radio
                                    sx={{
                                      '&.Mui-checked': {
                                        color: '#fbc02d',
                                      },
                                      marginRight: '0.8rem',
                                    }}
                                    className="radio-btn"
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body"
                                    className={`question-key ${
                                      answerList.some(
                                        (answer) =>
                                          answer.questionId === question.id &&
                                          (JSON.parse(answer.answer) !== question.answer) &
                                            (question.answer === item),
                                      )
                                        ? 'true'
                                        : 'false'
                                    }`}
                                  >
                                    {item}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <br></br>
                </Box>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Choose;
