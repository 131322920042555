import {
  Box,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  styled,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import userServices from '../../services/userServices';
import './SignUp.scss';
import { GoogleLogin } from '@react-oauth/google';

const StyledSpan = styled('span')(({ theme }) => ({
  color: '#fbc02d',
  fontWeight: 'bold',
  '&:hover': {
    cursor: 'pointer',
    fontStyle: 'italic',
  },
}));

function SignUp() {
  const navigate = useNavigate();
  const [email, setEmail] = useState({
    value: '',
    message: '',
  });
  const [name, setName] = useState({
    value: '',
    message: '',
  });
  const [password, setPassword] = useState({
    value: '',
    message: '',
    isShow: false,
  });
  const [secondPassword, setSecondPassword] = useState({
    value: '',
    message: '',
    isShow: false,
  });
  const [alert, setAlert] = useState({
    isShow: false,
    status: '',
    message: '',
  });

  const validateEmail = () => {
    let formatted = email.value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    if (!formatted) {
      setEmail({
        ...email,
        message: 'Email không hợp lệ.',
      });
      return false;
    }

    setEmail({ ...email, message: '' });

    return true;
  };

  const validatePassword = () => {
    if (password.value.length < 8) {
      setPassword({
        ...password,
        message: 'Mật khẩu phải có từ 8 ký tự trở lên.',
      });
      return false;
    }

    setPassword({ ...password, message: '' });

    return true;
  };

  const validateSecondPassword = () => {
    if (secondPassword.value !== password.value) {
      setSecondPassword({
        ...secondPassword,
        message: 'Nhập lại mật khẩu mới không chính xác.',
      });
      return false;
    }

    setSecondPassword({ ...secondPassword, message: '' });

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { email: email.value, name: name.value, password: password.value };

    const validEmail = validateEmail();
    const validPass = validatePassword();
    const validSecondPass = validateSecondPassword();

    if (validEmail && validPass && validSecondPass) {
      const registeredData = await userServices.signUp(data);

      if (registeredData.error === 'Email already exists!') {
        setAlert({
          isShow: true,
          status: 'error',
          message: 'Email đã tồn tại. Đăng ký tài khoản không thành công.',
        });
        setTimeout(() => {
          setAlert({
            isShow: false,
            status: '',
            message: '',
          });
        }, 3000);
      }
      if (!!registeredData.message) {
        setAlert({
          isShow: true,
          status: 'success',
          message: 'Đăng ký tài khoản thành công.',
        });
        setTimeout(() => {
          setAlert({
            isShow: false,
            status: '',
            message: '',
          });
        }, 3000);
        setTimeout(() => {
          navigate('/dang-nhap');
        }, 2000);
      }
    }
  };

  const handleSignupSuccess = async (response) => {
    try {
      const { credential } = response;
      const signupData = await userServices.googleSignup({ token: credential });

      if (signupData.error === 'Email already exists!') {
        setAlert({
          isShow: true,
          status: 'error',
          message: 'Email đã tồn tại. Vui lòng sử dụng tài khoản khác để đăng ký.',
        });
        setTimeout(() => {
          setAlert({
            isShow: false,
            status: '',
            message: '',
          });
        }, 3000);
      }
      if (!!signupData?.message) {
        setAlert({
          isShow: true,
          status: 'success',
          message: 'Đăng ký tài khoản thành công.',
        });
        setTimeout(() => {
          setAlert({
            isShow: false,
            status: '',
            message: '',
          });
        }, 3000);
        setTimeout(() => {
          navigate('/dang-nhap');
        }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSignupFailure = () => {
    setAlert({
      isShow: true,
      status: 'error',
      message: 'Đã có lỗi xảy ra. Đăng ký tài khoản không thành công.',
    });
    setTimeout(() => {
      setAlert({
        isShow: false,
        status: '',
        message: '',
      });
    }, 3000);
  };

  return (
    <Box id="signup-wrapper">
      <h1 style={{ fontSize: '3.2rem', color: '#000' }}>Đăng ký</h1>

      <Box component="form" onSubmit={handleSubmit} className="form-wrapper">
        <InputLabel required sx={{ fontSize: '1.6rem', mb: 1 }} htmlFor="name">
          Tên người dùng:{' '}
        </InputLabel>
        <OutlinedInput
          placeholder="Nhập tên người dùng"
          required
          fullWidth
          id="name"
          name="name"
          autoComplete="name"
          autoFocus
          onChange={(e) => setName({ ...name, value: e.target.value })}
        />

        <InputLabel required sx={{ fontSize: '1.6rem', mb: 1, mt: 2 }} htmlFor="email">
          Địa chỉ email:{' '}
        </InputLabel>
        <OutlinedInput
          placeholder="Nhập địa chỉ email"
          required
          fullWidth
          id="email"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(e) => setEmail({ ...email, value: e.target.value })}
        />
        {email.message && (
          <FormHelperText error id="component-error-text">
            {email.message}
          </FormHelperText>
        )}

        <InputLabel required sx={{ fontSize: '1.6rem', mb: 1, mt: 2 }} htmlFor="password">
          Mật khẩu:
        </InputLabel>
        <OutlinedInput
          placeholder="Nhập mật khẩu"
          required
          fullWidth
          name="password"
          type={password.isShow ? 'text' : 'password'}
          id="password"
          onChange={(e) => setPassword({ ...password, value: e.target.value })}
          endAdornment={
            password.isShow ? (
              <IconButton onClick={() => setPassword({ ...password, isShow: !password.isShow })}>
                <VisibilityIcon fontSize="large" />
              </IconButton>
            ) : (
              <IconButton onClick={() => setPassword({ ...password, isShow: !password.isShow })}>
                <VisibilityOffIcon fontSize="large" />
              </IconButton>
            )
          }
        />
        {password.message && (
          <FormHelperText error id="component-error-text">
            {password.message}
          </FormHelperText>
        )}

        <InputLabel required sx={{ fontSize: '1.6rem', mb: 1, mt: 2 }} htmlFor="second-password">
          Nhập lại mật khẩu:
        </InputLabel>
        <OutlinedInput
          placeholder="Nhập lại mật khẩu"
          required
          fullWidth
          name="second-password"
          type={secondPassword.isShow ? 'text' : 'password'}
          id="second-password"
          onChange={(e) => setSecondPassword({ ...secondPassword, value: e.target.value })}
          endAdornment={
            secondPassword.isShow ? (
              <IconButton
                onClick={() =>
                  setSecondPassword({ ...secondPassword, isShow: !secondPassword.isShow })
                }
              >
                <VisibilityIcon fontSize="large" />
              </IconButton>
            ) : (
              <IconButton
                onClick={() =>
                  setSecondPassword({ ...secondPassword, isShow: !secondPassword.isShow })
                }
              >
                <VisibilityOffIcon fontSize="large" />
              </IconButton>
            )
          }
        />
        {secondPassword.message && (
          <FormHelperText error id="component-error-text">
            {secondPassword.message}
          </FormHelperText>
        )}
        {alert.isShow && (
          <Alert severity={alert.status} className="alert">
            {alert.message}
          </Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2, backgroundColor: '#fdd835', color: '#000', fontSize: '1.6rem' }}
        >
          Đăng ký
        </Button>
        <Divider style={{ marginBottom: '1.6rem' }}>
          <span style={{ color: '#000' }}>hoặc</span>
        </Divider>

        {/* <div className="google-iframe-wrapper"> */}
        <div style={{ width: 'fit-content', margin: 'auto' }}>
          <GoogleLogin
            onSuccess={handleSignupSuccess}
            onError={handleSignupFailure}
            text="signup_with"
            logo_alignment="center"
            locale="vi-VN"
          />
        </div>
        {/* </div> */}

        <Typography variant="h5" color="#000" sx={{ mt: '2rem', textAlign: 'center' }}>
          Bạn đã có tài khoản?{' '}
          <StyledSpan onClick={() => navigate('/dang-nhap')}>Đăng nhập ngay</StyledSpan>
        </Typography>
      </Box>
    </Box>
  );
}

export default SignUp;
