import * as React from 'react';
import { useState } from 'react';
import { Box, Stack, Typography, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './ComboBox1.scss';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';
import { handleFillAnswers } from '../../../utils/handleInputAnswers';
import { OfflinePin } from '@mui/icons-material';

function ComboBox1({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  const optionList = React.useMemo(() => {
    return ex?.questions
      ?.map((question) => question.answer?.split(','))
      .flat()
      .sort(() => Math.random() - 0.5);
  }, [ex]);

  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  return (
    <>
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <div id="combobox1-wrapper">
        {ex.questions.length > 0 &&
          ex.questions.map((question) => {
            let wordsVsPinyin = question.content.split('/');
            let contentItems = wordsVsPinyin[0].split('_');
            if (contentItems[0] === '') contentItems = wordsVsPinyin[0].split('_').slice(1);
            return wordsVsPinyin[0].split('_')[0] === '' ? (
              <div style={{ display: 'flex', alignItems: 'center' }} key={question.id}>
                <Typography variant="body" marginRight="2rem">
                  {`(${question.ordinalNumber})`}
                </Typography>
                <Stack
                  direction="row"
                  className="question"
                  key={question.id}
                  sx={{ marginBottom: '1.2rem', width: '100%' }}
                >
                  {wordsVsPinyin[1] !== '' && (
                    <Typography className="subtitle">{`(${wordsVsPinyin[1]})`}</Typography>
                  )}
                  {contentItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <FormControl
                        className={`combo-box 
                          ${
                            isSubmitted
                              ? answerList?.some(
                                  (answer) =>
                                    answer.questionId === question.id &&
                                    JSON.parse(answer.result)[index] === 'true',
                                )
                                ? 'true'
                                : 'false'
                              : ''
                          }`}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          sx={{ fontSize: '1.6rem' }}
                          value={
                            isSubmitted
                              ? (() => {
                                  const answer = answerList?.find(
                                    (answer) => answer.questionId === question.id,
                                  )?.answer;
                                  return answer ? JSON.parse(answer)?.[index] || '' : '';
                                })()
                              : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                  index
                                ] || ''
                          }
                          onChange={(e) =>
                            handleFillAnswers(e, ex.exerciseType, question.id, index, setAnswerList)
                          }
                        >
                          {optionList.map((option, index) => {
                            const splitOption = option?.includes('(') && option?.split('(');
                            return option?.includes('(') ? (
                              <MenuItem
                                sx={{ fontSize: '1.6rem', display: 'flex' }}
                                key={index}
                                value={option}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography variant="body">{splitOption[0]}</Typography>
                                  <Typography fontSize="1.2rem">{`(${splitOption[1]}`}</Typography>
                                </div>
                              </MenuItem>
                            ) : (
                              <MenuItem sx={{ fontSize: '1.6rem' }} key={index} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      <Typography variant="body" style={{}}>
                        {item}
                      </Typography>
                    </React.Fragment>
                  ))}
                  <Box marginBottom="0.8rem" marginRight="2rem" marginLeft="1.2rem">
                    {question.images !== 'no' && (
                      <img src={question.images} alt="question" className="image" />
                    )}
                  </Box>
                </Stack>
              </div>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }} key={question.id}>
                <Typography variant="body" marginRight="2rem">
                  {`(${question.ordinalNumber})`}
                </Typography>
                <Stack
                  direction="row"
                  className="question"
                  key={question.id}
                  sx={{ marginBottom: '1.2rem', width: '100%' }}
                >
                  {wordsVsPinyin[1] !== '' && (
                    <Typography className="subtitle">{`(${wordsVsPinyin[1]})`}</Typography>
                  )}
                  {contentItems.map((item, index) => {
                    if (index !== contentItems.length - 1) {
                      return (
                        <React.Fragment key={index}>
                          <Typography variant="body">{item}</Typography>
                          <FormControl
                            className={`combo-box 
                          ${
                            isSubmitted
                              ? answerList?.some(
                                  (answer) =>
                                    answer.questionId === question.id &&
                                    JSON.parse(answer.result)[index] === 'true',
                                )
                                ? 'true'
                                : 'false'
                              : ''
                          }`}
                          >
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              sx={{ fontSize: '1.6rem' }}
                              value={
                                isSubmitted
                                  ? (() => {
                                      const answer = answerList?.find(
                                        (answer) => answer.questionId === question.id,
                                      )?.answer;
                                      return answer ? JSON.parse(answer)?.[index] || '' : '';
                                    })()
                                  : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                      index
                                    ] || ''
                              }
                              onChange={(e) =>
                                handleFillAnswers(
                                  e,
                                  ex.exerciseType,
                                  question.id,
                                  index,
                                  setAnswerList,
                                )
                              }
                            >
                              {optionList.map((option, index) => {
                                const splitOption = option?.includes('(') && option?.split('(');
                                return option?.includes('(') ? (
                                  <MenuItem
                                    sx={{ fontSize: '1.6rem', display: 'flex' }}
                                    key={index}
                                    value={option}
                                  >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <Typography variant="body">{splitOption[0]}</Typography>
                                      <Typography fontSize="1.2rem">{`(${splitOption[1]}`}</Typography>
                                    </div>
                                  </MenuItem>
                                ) : (
                                  <MenuItem sx={{ fontSize: '1.6rem' }} key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <div key={index}>
                          <Typography variant="body">{item}</Typography>
                        </div>
                      );
                    }
                  })}
                  <Box
                    marginTop="0.8rem"
                    marginLeft="1.2rem"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {question.images !== 'no' && (
                      <img src={question.images} alt="question" height="42rem" />
                    )}
                  </Box>
                </Stack>
              </div>
            );
          })}

        {isSubmitted && (
          <div className="result-wrapper">
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem', color: '#00baf2' }}>
              <OfflinePin sx={{ fontSize: '2.4rem' }} />
              <p style={{ fontWeight: 'bold', fontSize: '1.8rem', textDecoration: 'underline' }}>
                Đáp án:
              </p>
            </div>
            <Grid container style={{ marginTop: '4rem' }}>
              {ex.questions.map((question) => (
                <Grid item xs={6} md={6} lg={6} key={question.id} style={{ marginTop: '1.2rem' }}>
                  <Typography
                    variant="body"
                    marginRight="2rem"
                  >{`(${question.ordinalNumber})`}</Typography>
                  <Typography variant="body">{question.answer}</Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}

export default ComboBox1;
