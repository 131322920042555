import { legacy_createStore as createStore, combineReducers } from 'redux';
import userReducer from './userSlice';
import userAnswerSlice from './userAnswerSlice';
const rootReducer = combineReducers({
  user: userReducer,
  userAnswer: userAnswerSlice
});

const store = createStore(rootReducer);

export default store;
