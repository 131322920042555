import { AppBar, Box, CssBaseline, Stack, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import UserAvatar from '../../../components/UserAvatar/UserAvatar';
import { useSelector } from 'react-redux';

function Header() {
  const user = useSelector((state) => state.user).user;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#fdd835' }}
      >
        <Toolbar sx={{ display: 'flex', mx: 5 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
              flex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '5.2rem',
                height: '5.2rem',
                borderRadius: '50%',
                overflow: 'hidden',
                marginTop: '0.6rem',
                marginBottom: '0.6rem',
              }}
            >
              <Link to={'/'}>
                <img
                  src={logo}
                  alt="trungvanthuongthuong"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Link>
            </div>

            <div style={{ display: 'flex', gap: '0.4rem', alignItems: 'center' }}>
              <UserAvatar user={user} />
              {/* <Typography fontSize="1.4rem" color="#000">
                {user?.name}
              </Typography> */}
            </div>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
