import './ResultPopup.scss';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Box, Typography } from '@mui/material';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BarLoader } from 'react-spinners';

export default function ResultPopup(props) {
  const { open, setOpen, result, handleViewDetail, currentPartName } = props;
  const handleClose = () => {
    setOpen(false);
  };
  const partTotalQuestions = result?.data.reduce((total, item) => total + item.questionTotal, 0);
  const percentage = Math.ceil((result?.trueAnswerQuantity / partTotalQuestions) * 100);
  const typographyStyle = {
    fontSize: '1.4rem',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
  };
  return (
    <div className="result-popup-wrapper">
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textTransform: 'uppercase',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Kết quả
          <br />
          {currentPartName}
        </DialogTitle>
        {!!result ? (
          <DialogContent>
            <Box
              sx={{
                width: '9rem',
                height: '9rem',
                margin: '1.2rem auto',
              }}
            >
              <CircularProgressbarWithChildren
                value={percentage}
                styles={{
                  path: {
                    stroke: '#00df26',
                  },
                  trail: {
                    stroke: '#dedede',
                  },
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                  sx={{ fontSize: '2.4rem', fontWeight: 'bold', color: '#000' }}
                >
                  {percentage}%
                </Typography>
              </CircularProgressbarWithChildren>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '2.4rem',
              }}
            >
              <span
                style={{
                  fontSize: '1.5rem',
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0.8rem',
                }}
              >
                Số câu đúng: {result?.trueAnswerQuantity}
                <span style={{ fontSize: '2rem' }}>/</span>
                {partTotalQuestions}
              </span>
              {result?.data.map((item) => (
                <span key={item.exerciseId} style={typographyStyle}>
                  {item.exerciseOrd.includes('Text') || item.exerciseOrd.includes('第')
                    ? item.exerciseOrd
                    : item.exerciseOrd === '*'
                    ? 'New words'
                    : `Exercise ${item.exerciseOrd}`}
                  : {item.trueAnswerCount}
                  <span style={{ fontSize: '2rem' }}>/</span>
                  {item.questionTotal}
                </span>
              ))}
            </Box>
          </DialogContent>
        ) : (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem',
              marginBottom: '2rem',
            }}
          >
            <BarLoader color="#ffeb3b" width={200} speedMultiplier={1.5} />
            <Typography sx={{ fontSize: '1.4rem', mt: '1.2rem' }} color="rgba(0, 0, 0, 0.7)">
              Loading...
            </Typography>
          </div>
        )}
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            sx={{
              fontSize: '1.4rem',
              backgroundColor: 'transparent',
              border: '1px solid #f57f17',
              color: '#f57f17',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              marginTop: '1.2rem',
            }}
            onClick={handleClose}
            autoFocus
          >
            Thoát
          </Button>
          <Button
            variant="contained"
            sx={{
              fontSize: '1.4rem',
              backgroundColor: '#ffeb3b',
              color: '#000',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#fbc02d',
              },
              marginTop: '1.2rem',
            }}
            onClick={handleViewDetail}
            autoFocus
          >
            Xem chi tiết
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
