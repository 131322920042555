import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import './FillInTones.scss';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';
import { OfflinePin } from '@mui/icons-material';
import { parseData } from '../../../utils/parseData';

function FillInTones({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = React.useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);
  const imagesList = ex.questions.map((item, index) => {
    return {
      id: index + 1,
      image: item.images,
      answer: item.answer,
    };
  });

  const order = [2, 4, 1, 5, 3, 8, 6, 7];
  var optionList = order.map((orderId) => {
    const item = imagesList.find((imageItem) => imageItem.id === orderId);
    return { ...item };
  });

  const allowDrop = (ev) => {
    ev.preventDefault();
  };
  const drag = (ev) => {
    ev.dataTransfer.setData('text', ev.target.id);
  };
  const drop = (ev) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData('text');
    var target = ev.target;
    var questionId = target.dataset.questionId;

    if (
      (target.className.includes('box') && target.childNodes.length === 0) ||
      target.className.includes('option-list')
    ) {
      if (questionId) {
        // target.appendChild(document.getElementById(data));
        setAnswerList((preState) => {
          const filteredState = preState.filter(
            (item) => item.questionId !== questionId && item.answer.imageId !== data,
          );

          const newItem = {
            id: questionId,
            answer: {
              content: imagesList.find((item) => item.id === parseInt(data, 10)).answer,
              imageId: data,
            },
            exerciseType: ex.exerciseType,
          };
          return [...filteredState, newItem];
        });
      } else {
        setAnswerList((preState) => preState.filter((item) => item.answer.imageId !== data));
      }
    }
  };

  const touchStart = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const touch = ev.touches[0];
    ev.target.style.left = touch.clientX + 'px';
    ev.target.style.top = touch.clientY + 'px';
    ev.target.setAttribute('data-draggable-id', ev.target.id);
    ev.target.addEventListener('touchmove', touchMove, { passive: false });
  };

  const touchMove = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  const touchEnd = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const touch = ev.changedTouches[0];
    const target = document.elementFromPoint(touch.clientX, touch.clientY);

    const draggableId = ev.target.getAttribute('data-draggable-id');
    const questionId = target.dataset.questionId;

    if (
      (target.className.includes('box') && target.childNodes.length === 0) ||
      target.className.includes('option-list')
    ) {
      if (questionId) {
        // target.appendChild(elementToAppend);

        setAnswerList((preState) => {
          const filteredState = preState.filter(
            (item) => item.questionId !== questionId && item.answer.imageId !== draggableId,
          );

          const newItem = {
            id: questionId,
            answer: {
              content: imagesList.find((item) => item.id === parseInt(draggableId, 10)).answer,
              imageId: draggableId,
            },
            exerciseType: ex.exerciseType,
          };
          return [...filteredState, newItem];
        });
      } else {
        setAnswerList((preState) => preState.filter((item) => item.answer.imageId !== draggableId));
      }
    }
  };

  const displayValue = React.useCallback(
    (item) =>
      answerList.length > 0
        ? answerList.findIndex((ans) => {
            return (isSubmitted ? parseData(ans.answer) : ans.answer).imageId == item.id;
          }) !== -1
          ? 'none'
          : 'block'
        : 'block',
    [answerList, isSubmitted],
  );

  return (
    <div id="fill-in-tones-wrapper">
      {ex.record && <AudioBox exeAudio={currentAudio} />}

      <div
        direction="row"
        onDrop={(event) => drop(event)}
        onDragOver={(event) => allowDrop(event)}
        className="option-list"
        data-option-list="options"
      >
        {optionList.map((item, index) => (
          <div
            key={index}
            className="option-item-wrapper"
            style={{
              pointerEvents: isSubmitted ? 'none' : 'auto',
              display: displayValue(item),
            }}
          >
            <img
              className="option-item"
              id={item.id}
              draggable={!isSubmitted}
              onDragStart={(event) => drag(event)}
              onTouchStart={(event) => touchStart(event)}
              onTouchMove={(event) => touchMove(event)}
              onTouchEnd={(event) => touchEnd(event)}
              src={item.image}
              alt="option"
              width="80rem"
              height="42rem"
              style={{ border: 'border: 1px solid #fbc02d', display: 'block' }}
            />
          </div>
        ))}
      </div>

      <Grid container spacing={{ xs: 0, md: 2, lg: 0 }}>
        {ex.questions.map((question) => (
          <Grid item xs={6} md={4} lg={3} key={question.id} className="question">
            <Typography
              variant="body"
              marginRight="0.8rem"
            >{`(${question.ordinalNumber})`}</Typography>
            <div
              onDrop={(event) => drop(event)}
              onDragOver={(event) => allowDrop(event)}
              data-question-id={question.id}
              style={{ pointerEvents: isSubmitted ? 'none' : 'auto' }}
              className={`box ${
                isSubmitted &&
                answerList.some(
                  (answer) => question.id === answer.questionId && answer.result === '0',
                )
                  ? 'false'
                  : answerList.some(
                      (answer) => question.id === answer.questionId && answer.result === '1',
                    )
                  ? 'true'
                  : ''
              }`}
            >
              {(() => {
                const userAnswer = imagesList.find((option) =>
                  isSubmitted && answerList.length > 0
                    ? answerList.some(
                        (answer) =>
                          option.id == parseData(answer?.answer)?.imageId &&
                          answer.questionId == question.id,
                      )
                    : answerList.some(
                        (answer) => option.id == answer.answer.imageId && answer.id == question.id,
                      ),
                );
                return userAnswer ? (
                  <img
                    className="option-item"
                    id={userAnswer.id}
                    draggable={!isSubmitted}
                    onDragStart={(event) => drag(event)}
                    onTouchStart={(event) => touchStart(event)}
                    onTouchMove={(event) => touchMove(event)}
                    onTouchEnd={(event) => touchEnd(event)}
                    src={userAnswer.image}
                    alt="option"
                    width="80rem"
                    height="42rem"
                    style={{ border: 'border: 1px solid #fbc02d', display: 'block' }}
                  />
                ) : null;
              })()}
            </div>
          </Grid>
        ))}
      </Grid>
      {isSubmitted && (
        <div className="result-wrapper">
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem', color: '#00baf2' }}>
            <OfflinePin sx={{ fontSize: '2.4rem' }} />
            <p style={{ fontWeight: 'bold', fontSize: '1.8rem', textDecoration: 'underline' }}>
              Đáp án:
            </p>
          </div>
          <Grid container style={{ marginTop: '4rem' }}>
            {ex.exerciseType === 'Fill in tones' &&
              ex.questions.map((question) => (
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ display: 'flex', justifyContent: 'center', marginTop: '1.2rem' }}
                  key={question.id}
                  display="flex"
                  alignItems="center"
                  marginBottom="0.8rem"
                >
                  <Typography
                    variant="body"
                    marginRight="2rem"
                  >{`(${question.ordinalNumber})`}</Typography>
                  <img src={question.images} alt="column" width="64rem" />
                </Grid>
              ))}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default FillInTones;
