import useSWR from 'swr';

export default function useAnswerList(userId, partId) {
  let url = `/users/${userId}/get-answer?partId=${partId}`;
  const fetcher = async (url) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`);
    return await res.json();
  };

  const { data, error, loading, mutate } = useSWR(url, fetcher);
  return {
    data,
    loading,
    error,
    mutate,
  };
}
