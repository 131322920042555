import React, { useState } from 'react';
import { Box, Collapse, Typography, Button, Stack } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import separateCharacters from '../../utils/separateCharacters';
import '../Exercise/Exercise.scss';

function Skill({ skill, exercises, currentExercise, handleChooseAnswer }) {
  const [openSkill, setOpenSkill] = useState(true);
  const separatedSkill = separateCharacters(skill.name);
  const filterdExercises = exercises.filter((exercise) => exercise.skillId === skill.id);

  return (
    <React.Fragment>
      <Box className="skill-list">
        <Box key={skill.id} onClick={() => setOpenSkill(!openSkill)} className="skill-item">
          {openSkill ? (
            <KeyboardArrowDownIcon className="skill-icon" />
          ) : (
            <KeyboardArrowRightIcon className="skill-icon" />
          )}
          <Box>
            <Typography variant="body" color="rgba(0, 0, 0, 0.7)">
              {separatedSkill[0]}
            </Typography>
            <br />
            <Typography sx={{ fontSize: '1.4rem' }} color="rgba(0, 0, 0, 0.7)">
              {separatedSkill[1]}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Stack
        direction="row"
        display="flex"
        justifyContent="space-around"
        flexWrap="wrap"
        padding="0 1.2rem 0 1.2rem"
        borderBottom="1px solid rgba(0, 0, 0, 0.2)"
      >
        {filterdExercises.length > 0 &&
          filterdExercises.map((ex, index) => (
            <Collapse in={openSkill} timeout="auto" unmountOnExit key={index}>
              <Button
                variant="contained"
                sx={{
                  fontSize: '1.6rem',
                  backgroundColor: '#ffeb3b',
                  color: '#000',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: '#fbc02d',
                  },
                  marginBottom: '1.2rem',
                  marginLeft: '0.6rem',
                }}
                onClick={() => handleChooseAnswer(ex)}
                className={`exercise-item ${currentExercise?.id === ex?.id ? 'selected' : ''}`}
              >
                {/* Số thứ tự exercise bình thường */}
                {ex.ordinalNumber}
              </Button>
            </Collapse>
          ))}
      </Stack>
    </React.Fragment>
  );
}

export default Skill;
