import * as React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { removeAccessToken } from '../../utils/cookies';
import { removeUserStorage } from '../../utils/storage';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/userSlice';
const emptyImage = require('../../assets/images/empty_topic.jpg');

export default function ErrorPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAsTeacher = () => {
    removeAccessToken();
    removeUserStorage();
    dispatch(setUser(null));
    navigate('/dang-nhap');
  };
  return (
    <Box
      id="error-page-wrapper"
      sx={{
        margin: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '600px',
      }}
    >
      <img src={emptyImage} alt="Not found" width="200px" />
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Trang bạn tìm không tồn tại hoặc không có quyền truy cập.
      </Typography>
      <div style={{ display: 'flex', gap: '2rem', marginTop: '3.2rem' }}>
        <Button
          variant="contained"
          sx={{
            fontSize: '1.4rem',
            backgroundColor: 'transparent',
            border: '1px solid #f57f17',
            color: '#f57f17',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={() => navigate('/')}
        >
          Về trang chủ
        </Button>
        <Button
          variant="contained"
          sx={{
            fontSize: '1.4rem',
            backgroundColor: '#ffeb3b',
            color: '#000',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#fbc02d',
            },
          }}
          onClick={handleAsTeacher}
        >
          Đăng nhập là giáo viên
        </Button>
      </div>
    </Box>
  );
}
