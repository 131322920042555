import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Button,
  IconButton,
  Alert,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import userServices from '../../services/userServices';
import './ChangePassword.scss';

export default function ChangePassword() {
  const { id } = useParams();
  const [isShowingOldPassword, setIsShowingOldPassword] = useState(false);
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const [isShowingSecondPassword, setIsShowingSecondPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [inputOldPassword, setInputOldPassword] = useState({
    value: '',
    message: '',
  });

  const [inputPassword, setInputPassword] = useState({
    value: '',
    message: '',
  });

  const [secondPassword, setSecondPassword] = useState({
    value: '',
    message: '',
  });

  const handleShowOldPassword = () => {
    setIsShowingOldPassword(!isShowingOldPassword);
  };

  const handleShowPassword = () => {
    setIsShowingPassword(!isShowingPassword);
  };

  const handleShowSecondPassword = () => {
    setIsShowingSecondPassword(!isShowingSecondPassword);
  };

  const validateOldPassword = () => {
    if (inputOldPassword.value === '') {
      setInputOldPassword({
        ...inputOldPassword,
        message: 'Vui lòng điền mật khẩu hiện tại.',
      });
      return false;
    }

    setInputOldPassword({ ...inputOldPassword, message: '' });

    return true;
  };

  const validatePassword = () => {
    if (inputPassword.value === '') {
      setInputPassword({
        ...inputPassword,
        message: 'Vui lòng điền mật khẩu mới.',
      });
      return false;
    }

    if (inputPassword.value.length < 8) {
      setInputPassword({
        ...inputPassword,
        message: 'Mật khẩu phải có từ 8 ký tự trở lên.',
      });
      return false;
    }

    setInputPassword({ ...inputPassword, message: '' });

    return true;
  };

  const validateSecondPassword = () => {
    if (secondPassword.value === '') {
      setSecondPassword({
        ...secondPassword,
        message: 'Vui lòng nhập lại mật khẩu mới.',
      });
      return false;
    }

    if (secondPassword.value !== inputPassword.value) {
      setSecondPassword({
        ...secondPassword,
        message: 'Nhập lại mật khẩu mới không chính xác.',
      });
      return false;
    }

    setSecondPassword({ ...secondPassword, message: '' });

    return true;
  };

  const handleChangePassword = async () => {
    try {
      const validOldPass = validateOldPassword();
      const validPass = validatePassword();
      const validSecondPass = validateSecondPassword();

      const data = {
        id: parseInt(id, 10),
        oldPass: inputOldPassword.value,
        newPass: inputPassword.value,
      };

      if (validOldPass && validPass && validSecondPass) {
        const result = await userServices.changePassword(data);
        if (result.error === 'Wrong current password!') {
          setInputOldPassword({
            ...inputOldPassword,
            message: 'Mật khẩu hiện tại không chính xác.',
          });
        }
        if (!!result.message) {
          setInputOldPassword({ value: '', message: '' });
          setInputPassword({ value: '', message: '' });
          setSecondPassword({ value: '', message: '' });
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="change-password-wrapper">
      <h1 className="page-title">Đổi mật khẩu</h1>
      <div className="form-container">
        <Box className="input">
          <InputLabel required htmlFor="old-password" className="label">
            Mật khẩu hiện tại:
          </InputLabel>
          <div>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <OutlinedInput
                value={inputOldPassword.value}
                className="outline-input"
                required
                name="password"
                type={isShowingOldPassword ? 'text' : 'password'}
                style={{
                  fontSize: '1.6rem',
                }}
                id="old-password"
                onChange={(event) => {
                  setInputOldPassword((prevState) => ({
                    ...prevState,
                    value: event.target.value,
                    message: '',
                  }));
                }}
                error={!!inputOldPassword.message}
                onBlur={validateOldPassword}
              />
              <IconButton
                style={{ position: 'absolute', right: '0.8rem' }}
                onClick={handleShowOldPassword}
              >
                {isShowingOldPassword ? (
                  <VisibilityIcon sx={{ fontSize: '2rem' }} />
                ) : (
                  <VisibilityOffIcon sx={{ fontSize: '2rem' }} />
                )}
              </IconButton>
            </div>
            {inputOldPassword.message && (
              <FormHelperText error id="component-error-text">
                {inputOldPassword.message}
              </FormHelperText>
            )}
          </div>
        </Box>

        <Box className="input">
          <InputLabel required htmlFor="password" className="label">
            Mật khẩu mới:
          </InputLabel>
          <div>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <OutlinedInput
                value={inputPassword.value}
                className="outline-input"
                required
                name="password"
                type={isShowingPassword ? 'text' : 'password'}
                style={{
                  fontSize: '1.6rem',
                }}
                id="password"
                onChange={(event) => {
                  setInputPassword((prevState) => ({
                    ...prevState,
                    value: event.target.value,
                    message: '',
                  }));
                }}
                error={!!inputPassword.message}
                onBlur={validatePassword}
              />
              <IconButton
                style={{ position: 'absolute', right: '0.8rem' }}
                onClick={handleShowPassword}
              >
                {isShowingPassword ? (
                  <VisibilityIcon sx={{ fontSize: '2rem' }} />
                ) : (
                  <VisibilityOffIcon sx={{ fontSize: '2rem' }} />
                )}
              </IconButton>
            </div>
            {inputPassword.message && (
              <FormHelperText error id="component-error-text">
                {inputPassword.message}
              </FormHelperText>
            )}
          </div>
        </Box>

        <Box className="input">
          <InputLabel required htmlFor="second-password" className="label">
            Nhập lại mật khẩu mới:
          </InputLabel>
          <div>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <OutlinedInput
                value={secondPassword.value}
                className="outline-input"
                required
                name="password"
                type={isShowingSecondPassword ? 'text' : 'password'}
                style={{
                  fontSize: '1.6rem',
                }}
                id="second-password"
                onChange={(event) => {
                  setSecondPassword((prevState) => ({
                    ...prevState,
                    value: event.target.value,
                    message: '',
                  }));
                }}
                error={!!secondPassword.message}
                onBlur={validateSecondPassword}
              />
              <IconButton
                style={{ position: 'absolute', right: '0.8rem' }}
                onClick={handleShowSecondPassword}
              >
                {isShowingSecondPassword ? (
                  <VisibilityIcon sx={{ fontSize: '2rem' }} />
                ) : (
                  <VisibilityOffIcon sx={{ fontSize: '2rem' }} />
                )}
              </IconButton>
            </div>
            {secondPassword.message && (
              <FormHelperText error id="component-error-text">
                {secondPassword.message}
              </FormHelperText>
            )}
          </div>
        </Box>
        <div style={{ display: 'flex', gap: '2rem', justifyContent: 'end', marginTop: '3.2rem' }}>
          <Button
            variant="contained"
            sx={{
              fontSize: '1.4rem',
              backgroundColor: '#ffeb3b',
              color: '#000',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#fbc02d',
              },
            }}
            onClick={handleChangePassword}
            autoFocus
          >
            Đổi mật khẩu
          </Button>
        </div>
        {showAlert && (
          <Alert severity="success" className="alert">
            Thay đổi mật khẩu thành công!
          </Alert>
        )}
      </div>
    </div>
  );
}
