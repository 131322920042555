import {
  Box,
  Button,
  Divider,
  IconButton,
  InputLabel,
  OutlinedInput,
  styled,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/userSlice';
import userServices from '../../services/userServices';
import './Login.scss';
import { setAccessToken } from '../../utils/cookies';
import { setUserStorage } from '../../utils/storage';
import { GoogleLogin } from '@react-oauth/google';

const StyledSpan = styled('span')(({ theme }) => ({
  color: '#fbc02d',
  fontWeight: 'bold',
  '&:hover': {
    cursor: 'pointer',
    fontStyle: 'italic',
  },
}));

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectUrl = params.get('redirect') || '/';

  const [email, setEmail] = useState({
    value: '',
    message: '',
  });
  const [password, setPassword] = useState({
    value: '',
    message: '',
    isShow: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleSaveLoginData = (accessToken, id, roleId, data) => {
    navigate(redirectUrl);
    // add access token to cookie
    setAccessToken(accessToken);
    const userStorage = {
      id: id,
      roleId: roleId,
    };
    setUserStorage(userStorage);
    dispatch(setUser(data));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { email: email.value, password: password.value };
    const loginData = await userServices.login(data);
    if (!!loginData?.message) {
      handleSaveLoginData(
        loginData.data.accessToken,
        loginData.data.id,
        loginData.data.roleId,
        loginData.data,
      );
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  const handleLoginSuccess = async (response) => {
    try {
      const { credential } = response;
      const loginData = await userServices.googleLogin({ token: credential });
      if (!!loginData?.message) {
        handleSaveLoginData(
          loginData.data.accessToken,
          loginData.data.id,
          loginData.data.roleId,
          loginData.data,
        );
      } else {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoginFailure = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  return (
    <Box id="login-wrapper">
      <h1 style={{ fontSize: '3.2rem', color: '#000' }}>Đăng nhập</h1>

      <Box component="form" onSubmit={handleSubmit} className="form-wrapper">
        <InputLabel required sx={{ fontSize: '1.6rem', mb: 1 }} htmlFor="email">
          Địa chỉ email:{' '}
        </InputLabel>
        <OutlinedInput
          placeholder="Nhập địa chỉ email"
          required
          fullWidth
          id="email"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(e) => setEmail({ ...email, value: e.target.value })}
        />
        <InputLabel required sx={{ fontSize: '1.6rem', mb: 1, mt: 2 }} htmlFor="password">
          Mật khẩu:
        </InputLabel>
        <OutlinedInput
          placeholder="Nhập mật khẩu"
          required
          fullWidth
          name="password"
          type={showPassword ? 'text' : 'password'}
          id="password"
          onChange={(e) => setPassword({ ...password, value: e.target.value })}
          endAdornment={
            showPassword ? (
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                <VisibilityIcon fontSize="large" />
              </IconButton>
            ) : (
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                <VisibilityOffIcon fontSize="large" />
              </IconButton>
            )
          }
        />
        <Typography
          variant="h5"
          color="#fbc02d"
          width="fit-content"
          sx={{
            display: 'inline',
            mt: '1rem',
            '&:hover': {
              cursor: 'pointer',
              fontStyle: 'italic',
            },
          }}
          onClick={() => navigate('/quen-mat-khau')}
        >
          Quên mật khẩu?
        </Typography>
        {showAlert && (
          <Alert severity="error" className="alert">
            Thông tin đăng nhập sai hoặc tài khoản chưa được đăng ký. Hãy kiểm tra lại.
          </Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2, backgroundColor: '#fdd835', color: '#000', fontSize: '1.6rem' }}
        >
          Đăng nhập
        </Button>
        <Divider style={{ marginBottom: '1.6rem' }}>
          <span style={{ color: '#000' }}>hoặc</span>
        </Divider>

        {/* <div className="google-iframe-wrapper"> */}
        <div style={{ width: 'fit-content', margin: 'auto' }}>
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={handleLoginFailure}
            logo_alignment="center"
            locale="vi-VN"
          />
        </div>
        {/* </div> */}

        <Typography variant="h5" color="#000" sx={{ mt: '2rem', textAlign: 'center' }}>
          Bạn chưa có tài khoản?{' '}
          <StyledSpan onClick={() => navigate('/dang-ky')}>Đăng ký ngay</StyledSpan>
        </Typography>
      </Box>
    </Box>
  );
}

export default Login;
