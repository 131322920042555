import * as React from 'react';
import { useState } from 'react';
import { Grid } from '@mui/material';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import './ChooseImage.scss';
import ReactAudioPlayer from 'react-audio-player';
import { useSelector } from 'react-redux';

function ChooseImage({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = useState(null);
  const [audioKey, setAudioKey] = React.useState(0);
  const [clickedAudio, setClickedAudio] = React.useState(false);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  React.useEffect(() => {
    setCurrentAudio(null);
  }, [ex]);

  const handlePlayAudio = (record) => {
    const audio = new Audio(record); // Create an audio element with the record URL
    audio.addEventListener('loadedmetadata', () => {
      const durationInSeconds = audio.duration; // Get the duration in seconds
      const durationInMilliseconds = durationInSeconds * 1000; // Convert to milliseconds

      setAudioKey((prevKey) => prevKey + 1);

      setCurrentAudio(record);
      setClickedAudio(true);
      setTimeout(() => {
        setClickedAudio(false);
      }, durationInMilliseconds);
    });
  };

  const handleSelectAnswer = (questionId, answerIndex) => {
    if (!isSubmitted) {
      setAnswerList((prevState) => {
        const updatedAnswers = prevState.map((answer) => {
          if (answer.id === questionId) {
            return { id: questionId, answer: answerIndex, exerciseType: ex.exerciseType };
          }
          return answer;
        });
        const isNewAnswer = !prevState.some((answer) => answer.id === questionId);
        if (isNewAnswer) {
          updatedAnswers.push({
            id: questionId,
            answer: answerIndex,
            exerciseType: ex.exerciseType,
          });
        }
        return updatedAnswers;
      });
    }
  };

  return (
    <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div id="choose-image-wrapper">
          {ex.questions.length > 0 &&
            ex.questions.map((question) => (
              <Grid container key={question.id}>
                <Grid
                  item
                  xs={2}
                  md={2}
                  lg={2}
                  className={`item ${
                    isSubmitted &&
                    answerList.findIndex((item) => item.questionId === question.id) === -1
                      ? 'not-choose'
                      : ''
                  }`}
                >
                  <HeadphonesIcon
                    className={`audio ${
                      clickedAudio && currentAudio === question.record ? 'clicked' : ''
                    }`}
                    sx={{ fontSize: '6rem' }}
                    onClick={() => handlePlayAudio(question.record)}
                  />
                </Grid>
                {question.images.split(',').map((image, index) => (
                  <Grid
                    key={index}
                    item
                    xs={(12 - 2) / question.images.split(',').length}
                    md={(12 - 2) / question.images.split(',').length}
                    lg={(12 - 2) / question.images.split(',').length}
                    className={`item 
                  ${
                    !isSubmitted
                      ? answerList.some(
                          (answer) => answer.id === question.id && answer.answer === index,
                        )
                        ? 'clicked'
                        : ''
                      : (() => {
                          const answerItem = answerList.find(
                            (answer) =>
                              answer.questionId === question.id &&
                              JSON.parse(answer.answer) === index,
                          );

                          if (answerItem) {
                            return answerItem.result === '1'
                              ? 'true'
                              : answerItem.result === '0'
                              ? 'false'
                              : '';
                          }
                          return '';
                        })()
                  }`}
                    onClick={() => handleSelectAnswer(question.id, index)}
                  >
                    <img src={image} alt={'logo'} className="image" />
                  </Grid>
                ))}
              </Grid>
            ))}
          {currentAudio && (
            <ReactAudioPlayer src={currentAudio} autoPlay controls style={{ display: 'none' }} />
          )}
        </div>
      </div>
    </>
  );
}

export default ChooseImage;
