import * as React from 'react';
import { Box, Stack, Typography, Grid } from '@mui/material';
import './DragToDrop.scss';
import answerServices from '../../../services/answerServices';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';
import { OfflinePin } from '@mui/icons-material';
import { parseData } from '../../../utils/parseData';

function DragToDrop({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = React.useState(ex.record ? ex.record : null);
  const [optionList, setOptionList] = React.useState([]);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);

  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
    setQuestionWithAnswer(null);
    answerServices
      .getByExerciseId(ex.id)
      .then((data) =>
        setOptionList(
          data.map((item, index) => {
            const label = String.fromCharCode(65 + index);
            return {
              label: label,
              ...item,
            };
          }),
        ),
      )
      .catch((err) => console.log(err));
  }, [ex]);

  const [questionWithAnswer, setQuestionWithAnswer] = React.useState();

  const GetExerciseWithAnswer = () => {
    const questionList = [...ex.questions];
    const questionsWithAnswer = questionList.map((ques) => {
      const foundItem = optionList.find((item) => item.questionId === ques.id);
      return {
        ...ques,
        answer: foundItem ? foundItem.content : '',
        label: foundItem ? foundItem.label : '',
      };
    });
    setQuestionWithAnswer(questionsWithAnswer);
  };

  React.useEffect(() => {
    if (optionList.length > 0) {
      GetExerciseWithAnswer();
    }
  }, [optionList]);

  const allowDrop = (ev) => {
    ev.preventDefault();
  };

  const drag = (ev) => {
    ev.dataTransfer.setData('text', ev.target.id);
    ev.dataTransfer.setData('data', ev.target.dataset.option);
    ev.dataTransfer.setData('questionId', ev.target.dataset.questionId);
  };

  const drop = (ev) => {
    ev.preventDefault();
    var id = ev.dataTransfer.getData('text');
    var data = ev.dataTransfer.getData('data');
    var dataQuesId = ev.dataTransfer.getData('questionId');
    var target = ev.target;
    var questionId = target.dataset.questionId;

    if (target.getElementsByClassName('box').length === 0 && target.className !== 'option-item') {
      if (!answerList.some((answer) => answer.id === questionId)) {
        // target.appendChild(document.getElementById(id));

        if (questionId) {
          setAnswerList((preState) => {
            const filteredState = preState.filter(
              (item) => item.questionId !== questionId && item.answer.contentId !== id,
            );

            const newItem = {
              id: questionId,
              answer: {
                content: data,
                contentId: id,
                questionItemId: parseInt(dataQuesId),
              },
              exerciseType: ex.exerciseType,
            };
            return [...filteredState, newItem];
          });
        } else {
          setAnswerList((preState) => preState.filter((item) => item.answer.contentId !== id));
        }
      }
    }
  };

  const touchStart = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.target.setAttribute('data-draggable-id', ev.target.id);
    ev.target.setAttribute('data-draggable-data', ev.target.dataset.option);
    ev.target.setAttribute('data-draggable-questionId', ev.target.dataset.questionId);

    ev.target.addEventListener('touchmove', touchMove, { passive: false });
  };

  const touchMove = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  const touchEnd = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const touch = ev.changedTouches[0];
    const target = document.elementFromPoint(touch.clientX, touch.clientY);

    const draggableId = ev.target.getAttribute('data-draggable-id');
    const draggableData = ev.target.getAttribute('data-draggable-data');
    const draggableQuestionId = ev.target.getAttribute('data-draggable-questionId');
    const questionId = target.dataset.questionId;
    if (
      (target.className.includes('box') || target.className.includes('option-list')) &&
      target.getElementsByClassName('box').length === 0
    ) {
      if (!answerList.some((answer) => answer.id === questionId)) {
        // target.appendChild(document.getElementById(draggableId));

        if (questionId) {
          setAnswerList((preState) => {
            const filteredState = preState.filter(
              (item) => item.questionId !== questionId && item.answer.contentId !== draggableId,
            );

            const newItem = {
              id: questionId,
              answer: {
                content: draggableData,
                contentId: draggableId,
                questionItemId: parseInt(draggableQuestionId),
              },
              exerciseType: ex.exerciseType,
            };
            return [...filteredState, newItem];
          });
        } else {
          setAnswerList((preState) =>
            preState.filter((item) => item.answer.contentId !== draggableId),
          );
        }
      }
    }
  };

  const displayValue = React.useCallback(
    (item) =>
      answerList.length > 0
        ? answerList.findIndex((ans) => {
            return (isSubmitted ? parseData(ans.answer) : ans.answer).contentId == item.id;
          }) !== -1
          ? 'none'
          : 'block'
        : 'block',
    [answerList, isSubmitted],
  );
  return (
    <>
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <div id="drag-to-drop-wrapper">
        <div
          direction="row"
          onDrop={(event) => drop(event)}
          data-option-list="options"
          onDragOver={(event) => allowDrop(event)}
          className="option-list"
        >
          {optionList?.map((item, index) => (
            <div
              key={item.id}
              className="option-item-wrapper"
              style={{
                pointerEvents: isSubmitted ? 'none' : 'auto',
                display: displayValue(item),
              }}
            >
              <div
                id={`${item.id}`}
                draggable={!isSubmitted}
                data-question-id={item.questionId}
                data-option={item.content}
                onDragStart={(event) => drag(event)}
                onTouchStart={(event) => touchStart(event)}
                onTouchMove={(event) => touchMove(event)}
                onTouchEnd={(event) => touchEnd(event)}
                key={index}
                className="option-item"
              >
                {item.label + '. ' + item.content}
              </div>
            </div>
          ))}
        </div>
        {ex?.questions?.length > 0 &&
          ex?.questions?.map((question, index) => {
            let flag = 0;
            let contentList = question.content.split('/');
            if (contentList.length === 2) flag = 1;

            return (
              <Stack direction="row" className="question" key={question.id}>
                <Typography variant="body" marginRight="2rem">
                  {`(${question.ordinalNumber})`}
                </Typography>
                <Box>
                  {contentList.map((content, index) => {
                    let contentItems = content.split('_');
                    return contentItems[0] === '' ? (
                      <Box
                        className="question-content"
                        key={index}
                        sx={{
                          width: index === 1 ? '100%' : 'contents',
                          marginTop: index === 1 && '1.2rem',
                        }}
                      >
                        {contentItems.slice(1)?.map((item, i) => (
                          <div key={i} style={{ display: 'contents' }} className="main-content">
                            <div
                              onDrop={(event) => drop(event)}
                              data-question-id={question.id}
                              data-question-index={i}
                              onDragOver={(event) => allowDrop(event)}
                              className={`box 
                              ${
                                isSubmitted &&
                                answerList.some(
                                  (answer) =>
                                    question.id === answer.questionId && answer.result === '0',
                                )
                                  ? 'false'
                                  : answerList.some(
                                      (answer) =>
                                        question.id === answer.questionId && answer.result === '1',
                                    )
                                  ? 'true'
                                  : ''
                              }`}
                            >
                              {(() => {
                                const userAnswer = optionList.find((option) =>
                                  isSubmitted && answerList.length > 0
                                    ? answerList.some(
                                        (answer) =>
                                          (option.id == parseData(answer?.answer)?.contentId ||
                                            option.questionId === null) &&
                                          answer.questionId == question.id,
                                      )
                                    : answerList.some(
                                        (answer) =>
                                          (option.id == answer.answer.contentId ||
                                            option.questionId === null) &&
                                          answer.id == question.id,
                                      ),
                                );
                                return userAnswer ? (
                                  <div
                                    id={`${userAnswer.id}`}
                                    draggable={!isSubmitted}
                                    data-question-id={userAnswer.questionId}
                                    data-option={userAnswer.content}
                                    onDragStart={(event) => drag(event)}
                                    onTouchStart={(event) => touchStart(event)}
                                    onTouchMove={(event) => touchMove(event)}
                                    onTouchEnd={(event) => touchEnd(event)}
                                    className="option-item"
                                    style={{ backgroundColor: 'white' }}
                                  >
                                    {`${userAnswer.label}. ${userAnswer.content}`}
                                  </div>
                                ) : null;
                              })()}
                            </div>
                            <Typography variant="body" style={{ lineHeight: '3.2rem' }}>
                              {flag === 1 ? (index === 0 ? 'A: ' : 'B: ') : '' + item}
                            </Typography>
                          </div>
                        ))}

                        <Box marginRight="2rem" marginBottom="-0.6rem">
                          {question.images !== 'no' && (
                            <img
                              src={question.images}
                              alt="question"
                              draggable="false"
                              className="image"
                            />
                          )}
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        className="question-content"
                        key={index}
                        sx={{
                          width: index === 1 ? '100%' : 'contents',
                          marginTop: index === 1 && '1.2rem',
                        }}
                      >
                        {contentItems?.map((item, i) => {
                          if (i !== contentItems.length - 1)
                            return (
                              <div key={i} style={{ display: 'contents' }}>
                                <Typography variant="body" style={{ lineHeight: '3.2rem' }}>
                                  {(flag === 1 ? (index === 0 ? 'A: ' : 'B: ') : '') + item}
                                </Typography>
                                <div
                                  onDrop={(event) => drop(event)}
                                  data-question-id={question.id}
                                  data-question-index={i}
                                  onDragOver={(event) => allowDrop(event)}
                                  className={`box 
                              ${
                                isSubmitted &&
                                answerList.some(
                                  (answer) =>
                                    question.id === answer.questionId && answer.result === '0',
                                )
                                  ? 'false'
                                  : answerList.some(
                                      (answer) =>
                                        question.id === answer.questionId && answer.result === '1',
                                    )
                                  ? 'true'
                                  : ''
                              }`}
                                >
                                  {(() => {
                                    const userAnswer = optionList.find((option) =>
                                      isSubmitted && answerList.length > 0
                                        ? answerList.some(
                                            (answer) =>
                                              (option.id == parseData(answer?.answer)?.contentId ||
                                                option.questionId === null) &&
                                              answer.questionId == question.id,
                                          )
                                        : answerList.some(
                                            (answer) =>
                                              (option.id == answer.answer.contentId ||
                                                option.questionId === null) &&
                                              answer.id == question.id,
                                          ),
                                    );
                                    return userAnswer ? (
                                      <div
                                        id={`${userAnswer.id}`}
                                        draggable={!isSubmitted}
                                        data-question-id={userAnswer.questionId}
                                        data-option={userAnswer.content}
                                        onDragStart={(event) => drag(event)}
                                        onTouchStart={(event) => touchStart(event)}
                                        onTouchMove={(event) => touchMove(event)}
                                        onTouchEnd={(event) => touchEnd(event)}
                                        className="option-item"
                                        style={{ backgroundColor: 'white' }}
                                      >
                                        {`${userAnswer.label}. ${userAnswer.content}`}
                                      </div>
                                    ) : null;
                                  })()}
                                </div>
                              </div>
                            );
                          else
                            return (
                              <Typography variant="body" key={i} style={{ lineHeight: '3.2rem' }}>
                                {(contentItems.length === 1 ? (index === 0 ? 'A: ' : 'B: ') : '') +
                                  item}
                              </Typography>
                            );
                        })}

                        <Box marginRight="2rem" marginBottom="-0.6rem">
                          {question.images !== 'no' && (
                            <img
                              src={question.images}
                              alt="question"
                              draggable="false"
                              className="image"
                            />
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Stack>
            );
          })}
        {isSubmitted && (
          <div className="result-wrapper">
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem', color: '#00baf2' }}>
              <OfflinePin sx={{ fontSize: '2.4rem' }} />
              <p style={{ fontWeight: 'bold', fontSize: '1.8rem', textDecoration: 'underline' }}>
                Đáp án:
              </p>
            </div>
            <Grid
              container
              style={{ marginTop: '1.6rem', marginLeft: '2.8rem' }}
              className="answer-wrapper"
            >
              {questionWithAnswer?.map((question) => (
                <Grid item xs={6} md={6} lg={6} key={question.id} style={{ marginTop: '1.2rem' }}>
                  <Typography
                    variant="body"
                    marginRight="2rem"
                  >{`(${question.ordinalNumber})`}</Typography>
                  <Typography variant="body">{question.label + '. ' + question.answer}</Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}

export default DragToDrop;
