import * as React from 'react';
import { Grid, Box, Typography, TextField, Stack } from '@mui/material';
import './FillInNumber.scss';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';
import { handleFillAnswers } from '../../../utils/handleInputAnswers';
import { OfflinePin } from '@mui/icons-material';

function FillInNumber({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = React.useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);

  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  return (
    <div className="fill-in-number-wrapper">
      <div style={{ fontSize: '1.4rem', fontStyle: 'italic' }}>
        *Lưu ý: Trên mỗi dòng chỉ điền 1 số và không có ký tự gì đi kèm.
      </div>
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <Grid container className="question-7-wrapper" spacing={{ xs: 1, md: 2, lg: 2 }}>
        {ex.questions.length > 0 &&
          ex.exerciseType === 'Fill in number' &&
          ex.questions.map((question) => {
            let answersList = question.answer.split(',');
            return (
              <Grid
                item
                key={question.id}
                xs={12 / ex.questions.length}
                md={12 / ex.questions.length}
                lg={12 / ex.questions.length}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box
                      className={`question-key ${
                        isSubmitted
                          ? answerList?.some(
                              (answer) =>
                                answer.questionId === question.id &&
                                !JSON.parse(answer.result)?.includes('false'),
                            )
                            ? 'true'
                            : 'false'
                          : ''
                      }`}
                    >
                      <img draggable={false} src={question.images} alt="column" width="64rem" />
                    </Box>
                    {answersList.map((item, index) => (
                      <TextField
                        key={index}
                        id="standard-basic"
                        variant="standard"
                        sx={{
                          width: '6.4rem',
                        }}
                        className={`textfield-word ${
                          isSubmitted
                            ? answerList?.some(
                                (answer) =>
                                  answer.questionId === question.id &&
                                  JSON.parse(answer.result)?.[index] === 'true',
                              )
                              ? 'true'
                              : 'false'
                            : ''
                        }`}
                        value={
                          isSubmitted
                            ? (() => {
                                const answer = answerList?.find(
                                  (answer) => answer.questionId === question.id,
                                )?.answer;
                                return answer ? JSON.parse(answer)?.[index] || '' : '';
                              })()
                            : answerList?.find((answer) => answer.id === question.id)?.data?.[
                                index
                              ] || ''
                        }
                        onChange={(e) =>
                          handleFillAnswers(e, ex.exerciseType, question.id, index, setAnswerList)
                        }
                      />
                    ))}
                  </Stack>
                </div>
              </Grid>
            );
          })}
      </Grid>
      {isSubmitted && (
        <div className="result-wrapper">
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem', color: '#00baf2' }}>
            <OfflinePin sx={{ fontSize: '2.4rem' }} />
            <p style={{ fontWeight: 'bold', fontSize: '1.8rem', textDecoration: 'underline' }}>
              Đáp án:
            </p>
          </div>
          <Grid container style={{ marginTop: '4rem' }}>
            {ex.exerciseType === 'Fill in number' &&
              ex.questions.map((question) => (
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  key={question.id}
                  display="flex"
                  style={{ display: 'flex', justifyContent: 'center', marginTop: '1.2rem' }}
                  alignItems="center"
                  marginBottom="0.8rem"
                >
                  <img src={question.images} alt="column" width="64rem" />
                  <Typography variant="body" marginLeft="1.2rem">
                    {question.answer.split(',').join(', ')}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default FillInNumber;
