import * as React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import StarIcon from '@mui/icons-material/Star';
import './Choose2.scss';
import { useSelector } from 'react-redux';

function Choose2({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = useState(null);
  const [audioKey, setAudioKey] = React.useState(0);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  React.useEffect(() => {
    setCurrentAudio(null);
  }, [ex]);

  const handleSelectAnswer = (questionId, answerItem) => {
    if (!isSubmitted) {
      setAnswerList((prevState) => {
        const updatedAnswers = prevState.map((answer) => {
          if (answer.id === questionId) {
            return { id: questionId, answer: answerItem, exerciseType: ex.exerciseType };
          }
          return answer;
        });
        const isNewAnswer = !prevState.some((answer) => answer.id === questionId);
        if (isNewAnswer) {
          updatedAnswers.push({
            id: questionId,
            answer: answerItem,
            exerciseType: ex.exerciseType,
          });
        }
        return updatedAnswers;
      });
    }
  };

  return (
    <>
      <div id="choose2-wrapper">
        <Grid container>
          {ex.questions.length > 0 &&
            ex.questions.map((question) => (
              <Grid key={question.id} item className="grid-item" xs={12}>
                <Typography
                  variant="body"
                  className={`ordinal-number ${
                    isSubmitted &&
                    answerList.findIndex((item) => item.questionId === question.id) === -1
                      ? 'not-choose'
                      : ''
                  }`}
                >
                  {isNaN(question.ordinalNumber) === true ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body" fontSize="1.2rem">
                        {`(${question.ordinalNumber.split('(')[1]}`}
                      </Typography>
                      <Typography variant="body">{question.ordinalNumber.split('(')[0]}</Typography>
                    </Box>
                  ) : (
                    question.ordinalNumber + '.'
                  )}
                </Typography>
                <Grid container direction="row">
                  {question.content.split('/').map((content, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Typography sx={{ ml: '2.5rem' }} variant="body">
                          {index === 0 && content}
                        </Typography>
                        {index === 1 && (
                          <Box
                            sx={{
                              width: '100%',
                              margin: '1.2rem 2rem',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <StarIcon sx={{ color: '#09CEFA', fontSize: '2rem', mr: '0.8rem' }} />
                            <Typography
                              variant="body"
                              sx={{ display: 'inline', fontWeight: 'bold' }}
                            >
                              {content}
                            </Typography>
                          </Box>
                        )}
                        <Grid
                          container
                          className="option-list"
                          columnGap={{ xs: 2, md: 4, lg: 8 }}
                          wrap="nowrap"
                        >
                          {index === 2 &&
                            content.split(',').map((item) => (
                              <Grid
                                item
                                xs={12}
                                md={4}
                                lg={4}
                                key={item}
                                className={`option-item ${
                                  !isSubmitted
                                    ? answerList.some(
                                        (answer) =>
                                          answer.id === question.id && answer.answer === item,
                                      )
                                      ? 'clicked'
                                      : ''
                                    : (() => {
                                        const answerItem = answerList.find(
                                          (answer) =>
                                            answer.questionId === question.id &&
                                            JSON.parse(answer.answer) === item,
                                        );

                                        if (answerItem) {
                                          return answerItem.result === '1'
                                            ? 'true'
                                            : answerItem.result === '0'
                                            ? 'false'
                                            : '';
                                        }
                                        return '';
                                      })()
                                }`}
                                onClick={() => handleSelectAnswer(question.id, item)}
                              >
                                <Typography variant="body">{item}</Typography>
                              </Grid>
                            ))}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Grid>
            ))}
        </Grid>
        {currentAudio && (
          <ReactAudioPlayer
            key={audioKey}
            src={currentAudio}
            autoPlay
            controls
            style={{ display: 'none' }}
          />
        )}
      </div>
    </>
  );
}

export default Choose2;
