export const handleFillAnswers = (e, exerciseType, questionId, index, setInputAnswers) => {
  const newAnswerValue = e.target.value.toLowerCase();

  setInputAnswers((prevAnswers) => {
    const answerArray =
      prevAnswers.length > 0
        ? prevAnswers.find((answer) => answer.id === questionId)?.data || []
        : [];

    const updatedAnswerArray = [...answerArray];
    updatedAnswerArray[index] = newAnswerValue;

    if (prevAnswers.length === 0 || !prevAnswers.some((answer) => answer.id === questionId)) {
      const newAnswer = {
        id: questionId,
        data: updatedAnswerArray,
        exerciseType: exerciseType,
      };
      return [...prevAnswers, newAnswer];
    } else {
      const updatedAnswers = prevAnswers.map((answer) => {
        if (answer.id === questionId) {
          return {
            ...answer,
            data: updatedAnswerArray,
          };
        }
        return answer;
      });
      return updatedAnswers;
    }
  });
};

export const isIncluded = (answerList, questionId) => {
  return answerList.findIndex((item) => item.id === questionId) !== -1;
};
