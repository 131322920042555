import './WarningPopup.scss';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import extractPath from '../../utils/getRelativePath';

export default function WarningPopup(props) {
  const { warning, setWarning, handleChooseRedoExercises, toNextPart, toPreviousPart } = props;
  const navigate = useNavigate();

  const handleClose = () => {
    setWarning({ open: false, content: '', type: '' });
  };

  const directToLogin = () => {
    const redirectUrl = extractPath(window.location.href);
    navigate(`/dang-nhap?redirect=${encodeURIComponent(redirectUrl)}`);
  };

  return (
    <div className="warning-popup-wrapper">
      <Dialog open={warning.open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textTransform: 'uppercase',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Nhắc nhở nho nhỏ
        </DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: warning.content }} />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            sx={{
              fontSize: '1.4rem',
              backgroundColor: 'transparent',
              border: '1px solid #f57f17',
              color: '#f57f17',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              marginTop: '1.2rem',
            }}
            onClick={handleClose}
            autoFocus
          >
            Thoát
          </Button>
          <Button
            variant="contained"
            sx={{
              fontSize: '1.4rem',
              backgroundColor: '#ffeb3b',
              color: '#000',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#fbc02d',
              },
              marginTop: '1.2rem',
            }}
            onClick={() => {
              if (warning.type === 'Redo') handleChooseRedoExercises();
              else if (warning.type === 'NextPart') toNextPart();
              else if (warning.type === 'SubmitExercises') directToLogin();
              else toPreviousPart();
            }}
            autoFocus
          >
            {warning.type === 'Redo'
              ? 'Làm lại'
              : warning.type === 'SubmitExercises'
              ? 'Đăng nhập'
              : 'Chuyển part'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
