import Cookies from 'js-cookie';

export const setAccessToken = (token, expiredTime = '') => {
  Cookies.set('accessToken', token, {
    expires: expiredTime,
    secure: true, // Only over HTTPS
    sameSite: 'strict', // Prevent CSRF attacks
    path: '/', // Accessible to the entire domain
  });
};

export const getAccessToken = () => {
  return Cookies.get('accessToken');
};

export const removeAccessToken = () => {
  Cookies.remove('accessToken');
};
