import { createSlice } from '@reduxjs/toolkit';

// Define a thunk for fetching user answers from an API

const userAnswersSlice = createSlice({
  name: 'userAnswer',
  initialState: {
    userAnswer: [],
    isSubmitted: false,
  },
  reducers: {
    setUserAnswer: (state, action) => {
      state.userAnswer = action.payload;
    },
    setIsSubmitted: (state, action) => {
      state.isSubmitted = action.payload;
    },
  },
});

export const { setUserAnswer, setIsSubmitted } = userAnswersSlice.actions;

export default userAnswersSlice.reducer;
