import * as React from 'react';
import { Grid } from '@mui/material';
import './Group.scss';
import answerServices from '../../../services/answerServices';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';
import { parseData } from '../../../utils/parseData';

function Group({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = React.useState(ex.record ? ex.record : null);
  const [answers, setAnswers] = React.useState([]);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
    answerServices
      .getByExerciseId(ex.id)
      .then((data) =>
        setAnswers(
          data.map((item, index) => {
            const label = String.fromCharCode(65 + index);
            return {
              label: label,
              ...item,
            };
          }),
        ),
      )
      .catch((err) => console.log(err));
  }, [ex]);

  const getQuestionAnswerId = (data, index) => {
    const filteredItem = answers.find((ans) => ans.content === data);
    return !!filteredItem
      ? {
          content: data,
          id: filteredItem.questionId,
          label: filteredItem.label,
          index,
        }
      : null;
  };

  const handleGroupAnswers = (
    draggableData,
    exerciseType,
    questionId,
    index,
    inputAnswers,
    setInputAnswers,
  ) => {
    const newAnswerItem = getQuestionAnswerId(draggableData, index);

    setInputAnswers(() => {
      if (inputAnswers.length === 0) {
        return [
          {
            id: questionId,
            data: [newAnswerItem],
            exerciseType,
          },
        ];
      }

      const existingAnswerIndex = inputAnswers.findIndex((answer) => answer.id === questionId);

      const updatedAnswer = inputAnswers.map((answer) => {
        if (answer.data?.some((item) => item?.content === draggableData)) {
          return {
            ...answer,
            data: answer.data.filter((item) => item?.content !== draggableData),
          };
        }
        return answer;
      });

      if (existingAnswerIndex === -1) {
        updatedAnswer.push({
          id: questionId,
          data: [newAnswerItem],
          exerciseType,
        });
      } else {
        updatedAnswer[existingAnswerIndex].data.push(newAnswerItem);
      }
      return updatedAnswer;
    });
  };

  const allowDrop = (ev) => {
    ev.preventDefault();
  };

  const drag = (ev) => {
    ev.dataTransfer.setData('text', ev.target.id);
    ev.dataTransfer.setData('data', ev.target.dataset.option);
    ev.dataTransfer.setData('questionId', ev.target.dataset.questionId);
  };

  const drop = (ev) => {
    ev.preventDefault();
    // var id = ev.dataTransfer.getData('text');
    var data = ev.dataTransfer.getData('data');
    var target = ev.target;
    var questionId = target.dataset.questionId;
    var index = '';
    if (!!ev.target.getAttribute('data-index')) index = ev.target.getAttribute('data-index');
    if (target.getElementsByClassName('box')?.length === 0 && target.className !== 'option-item') {
      // target.appendChild(document.getElementById(id));
      handleGroupAnswers(data, ex.exerciseType, questionId, index, answerList, setAnswerList);
    }
  };

  const touchStart = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.target.setAttribute('data-draggable-id', ev.target.id);
    ev.target.setAttribute('data-draggable-data', ev.target.dataset.option);
    ev.target.setAttribute('data-draggable-questionId', ev.target.dataset.questionId);
    ev.target.addEventListener('touchmove', touchMove, { passive: false });
  };

  const touchMove = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  const touchEnd = (ev, index) => {
    ev.preventDefault();
    ev.stopPropagation();
    const touch = ev.changedTouches[0];
    const target = document.elementFromPoint(touch.clientX, touch.clientY);
    // const draggableId = ev.target.getAttribute('data-draggable-id');
    const draggableData = ev.target.getAttribute('data-draggable-data');
    const questionId = target.dataset.questionId;
    if (target.className !== 'option-item') {
      // target.appendChild(document.getElementById(draggableId));
      handleGroupAnswers(
        draggableData,
        ex.exerciseType,
        questionId,
        index,
        answerList,
        setAnswerList,
      );
    }
  };

  const numRows = Math.ceil(answers?.length / 4);

  // Create an array to represent rows
  const rows = Array.from({ length: numRows }, (_, index) =>
    answers.slice(index * 4, (index + 1) * 4),
  );

  const opacityValue = React.useCallback(
    (item) => {
      if (answerList.length > 0) {
        return answerList.findIndex((ans) => {
          const dataToCheck = isSubmitted ? parseData(ans.answer) : ans.data;

          if (Array.isArray(dataToCheck)) {
            return dataToCheck.some((ansItem) => ansItem.label === item.label);
          }
          return false;
        }) !== -1
          ? 0
          : 1;
      }
      return 1;
    },
    [answerList, isSubmitted],
  );

  return (
    <>
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <Grid container sx={{ p: 4, justifyContent: 'center' }} className="content-container">
        <div id="group-wrapper">
          <div className="group-container">
            <table
              className="option-list-table"
              style={{
                borderCollapse: 'collapse',
                width: '100%',
              }}
            >
              <colgroup>
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
              </colgroup>
              <tbody>
                {/* Map through the rows and create table rows */}
                {rows.map((row, index) => (
                  <tr key={index}>
                    {row.map((item) => (
                      <td
                        key={item.id}
                        style={{
                          border: '2px dashed #000',
                          backgroundColor: '#fff',
                        }}
                      >
                        <div
                          onDrop={(event) => drop(event)}
                          data-option-list="options"
                          onDragOver={(event) => allowDrop(event)}
                          style={{
                            paddingTop: '100%', // Set the height as a percentage of the width
                            position: 'relative',
                          }}
                        >
                          <div
                            id={`item-${item.id}`}
                            data-question-id={item.questionId}
                            draggable={!isSubmitted}
                            data-option={item.content}
                            onDragStart={(event) => drag(event)}
                            onTouchStart={(event) => touchStart(event)}
                            onTouchMove={(event) => touchMove(event)}
                            onTouchEnd={(event) => touchEnd(event)}
                            className="option-item"
                            style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                              fontSize: '3.2rem',
                              padding: '1rem',
                              textAlign: 'center',
                              backgroundColor: 'inherit',
                              opacity: opacityValue(item),
                            }}
                          >
                            {item.label + '. ' + item.content}
                          </div>
                        </div>
                      </td>
                    ))}

                    {row?.length < 4 &&
                      Array.from({ length: 4 - row?.length }).map((_, emptyCellIndex) => (
                        <td key={row?.length + emptyCellIndex}></td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Render 2 table to drop */}
            <Grid container spacing={2} paddingTop={2}>
              {ex.questions.map((question, index) => {
                let i = 0;
                const rowNumber = Math.floor(answers?.length / 4);
                const rows = Array.from({ length: rowNumber }, (_, index) =>
                  answers.slice(index * 2, (index + 1) * 2),
                );
                return (
                  <Grid item xs={6} md={6} key={index}>
                    <table
                      className={`option-list-table`}
                      style={{
                        borderCollapse: 'collapse',
                        width: '100%',
                        border: '1px #000 solid',
                        backgroundColor: '#fff',
                      }}
                    >
                      <colgroup>
                        <col style={{ width: '50%' }} />
                        <col style={{ width: '50%' }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th
                            style={{
                              backgroundColor: isSubmitted
                                ? answerList?.some(
                                    (ans) =>
                                      ans.questionId === question.id &&
                                      parseData(ans.result).length === 4 &&
                                      parseData(ans.result).every((res) => res === 'true'),
                                  )
                                  ? '#00ce17'
                                  : 'red'
                                : 'inherit',
                              padding: '0.4rem 0',
                              color: isSubmitted ? 'white' : 'black',
                            }}
                            colSpan="2"
                          >
                            {question.content}
                          </th>
                        </tr>
                        {rows.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {row.map((item) => {
                              i++;
                              return (
                                <td
                                  key={item.id}
                                  style={{
                                    border: '1px solid #000',
                                    backgroundColor: '#fff',
                                  }}
                                >
                                  <div
                                    style={{
                                      paddingTop: '100%',
                                      position: ' relative',
                                    }}
                                  >
                                    <div
                                      className="box"
                                      id={question.id}
                                      data-index={i}
                                      onDrop={(event) => drop(event)}
                                      data-question-id={question.id}
                                      onDragOver={(event) => allowDrop(event)}
                                      style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        right: '0',
                                        bottom: '0',
                                        padding: '1rem',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {(() => {
                                        const answerObject = answerList.find(
                                          (answer) =>
                                            (isSubmitted ? answer.questionId : answer.id) ==
                                            question.id,
                                        );

                                        const matchingItem = (
                                          isSubmitted
                                            ? parseData(answerObject?.answer)
                                            : answerObject?.data
                                        )?.find((item) => item.index === String(i));

                                        return matchingItem ? (
                                          <div
                                            id={`item-${matchingItem.id}`}
                                            data-question-id={matchingItem.questionId}
                                            draggable={!isSubmitted}
                                            data-option={matchingItem.content}
                                            onDragStart={(event) => drag(event)}
                                            onTouchStart={(event) => touchStart(event)}
                                            onTouchMove={(event) => touchMove(event)}
                                            onTouchEnd={(event) => touchEnd(event)}
                                            className="option-item"
                                            style={{
                                              position: 'absolute',
                                              top: '0',
                                              left: '0',
                                              right: '0',
                                              bottom: '0',
                                              fontSize: '3.2rem',
                                              padding: '1rem',
                                              textAlign: 'center',
                                              backgroundColor: 'inherit',
                                              color: isSubmitted
                                                ? matchingItem.id === question.id
                                                  ? '#00df26'
                                                  : 'red'
                                                : 'black',
                                            }}
                                          >
                                            {`${matchingItem.label}. ${matchingItem.content}`}
                                          </div>
                                        ) : null;
                                      })()}
                                    </div>
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default Group;
