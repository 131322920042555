import {
  Stack,
  IconButton,
  Menu,
  Typography,
  MenuItem,
  Avatar,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import './UserAvatar.scss';
import { removeAccessToken } from '../../utils/cookies';
import { removeUserStorage } from '../../utils/storage';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/userSlice';

export default function UserAvatar({ user }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickAvatar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    removeAccessToken();
    removeUserStorage();
    dispatch(setUser(null));
    navigate('/dang-nhap');
  };

  const directToProfilePage = (e) => {
    navigate(`/profile/${user?.id}`);
  };

  const directToChangePasswordPage = () => {
    navigate(`/change-password/${user?.id}`);
  };

  const directToPostManagement = () => {
    navigate('/quan-ly-bai-viet');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="user-avatar">
      <Stack
        direction="row"
        alignItems="center"
        onClick={handleClickAvatar}
        sx={{
          cursor: 'pointer',
        }}
      >
        <IconButton>
          <Avatar src={user?.avatar} />
        </IconButton>
        {isMobile && <Typography variant="h5">{user?.name}</Typography>}
      </Stack>
      <Menu
        onClick={(e) => e.stopPropagation()}
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            minWidth: '200px',
          },
        }}
      >
        <div
          style={{
            borderBottom: '1px solid #ccc',
            display: 'flex',
            gap: '1.6rem',
            padding: '10px 16px',
          }}
        >
          <Avatar src={user?.avatar} />
          <div>
            <Typography fontSize="1.6rem" fontWeight={700} className="user-name">
              {user?.name}
            </Typography>
            <Typography fontSize="1.4rem" color="#979797" fontWeight={500}>
              {user?.Role?.name}
            </Typography>
          </div>
        </div>
        <MenuItem onClick={directToProfilePage}>
          <Typography variant="h5">Trang cá nhân</Typography>
        </MenuItem>
        <MenuItem onClick={directToChangePasswordPage}>
          <Typography variant="h5">Đổi mật khẩu</Typography>
        </MenuItem>
        {user?.roleId === 2 && (
          <MenuItem onClick={directToPostManagement}>
            <Typography variant="h5">Quản lý bài viết</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={handleLogout}
          style={{
            borderTop: '1px solid #ccc',
          }}
        >
          <Typography variant="h5">Đăng xuất</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
