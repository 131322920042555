import * as React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import './ChooseMultiple.scss';
import { useState } from 'react';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';
import { OfflinePin } from '@mui/icons-material';

function ChooseMultiple({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  const handleSelecting = (questionId) => {
    if (!isSubmitted) {
      const itemIndex = answerList.findIndex((item) => item.id === questionId);
      if (itemIndex > -1) {
        const answersList = answerList.filter((item, index) => index !== itemIndex);
        setAnswerList(answersList);
      } else {
        const answersList = [...answerList];
        answersList.push({
          id: questionId,
          exerciseType: ex.exerciseType,
        });
        setAnswerList(answersList);
      }
    }
  };

  return (
    <div id="choose-multiple-wrapper">
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <Box sx={{ padding: '2rem' }}>
        <Grid container>
          {ex.questions?.map((item) => {
            let contentList = item.content.split('(');
            return (
              <Grid
                item
                key={item.id}
                xs={11.4}
                md={5.6}
                lg={5.6}
                onClick={() => handleSelecting(item.id)}
                style={{
                  cursor: isSubmitted ? 'context-menu' : 'pointer',
                  '&:active': { transform: isSubmitted ? 'scale(1)' : 'scale(0.95)' },
                }}
                className={`word-item ${
                  isSubmitted &&
                  answerList.some(
                    (answer) => item.id === answer.questionId && answer.result === '0',
                  )
                    ? 'false'
                    : answerList.some(
                        (answer) => item.id === answer.questionId && answer.result === '1',
                      )
                    ? 'true'
                    : ''
                }`}
              >
                {(isSubmitted
                  ? answerList.some((answer) => answer.questionId === item.id)
                  : answerList.some((selectedAnswer) => selectedAnswer.id === item.id)) && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="checkbox">
                      <p style={{ fontSize: '2.4rem' }}>✔</p>
                    </div>
                  </div>
                )}
                <Box className="text-wrapper">
                  <Typography variant="body" className="pinyin">
                    {`(${contentList[1]}`}
                  </Typography>
                  <br></br>
                  <Typography variant="body" className="text">
                    {contentList[0]}
                  </Typography>
                  {contentList[2] ? (
                    <div>
                      <Typography variant="body" className="meaning">
                        {contentList[2]}
                      </Typography>
                    </div>
                  ) : null}
                </Box>
                {item.images !== 'no' && (
                  <Box className="image-wrapper">
                    <img src={item.images} alt="question" className="image" />
                  </Box>
                )}
              </Grid>
            );
          })}
        </Grid>
      </Box>

      {isSubmitted && (
        <div className="result-wrapper">
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem', color: '#00baf2' }}>
            <OfflinePin sx={{ fontSize: '2.4rem' }} />
            <p style={{ fontWeight: 'bold', fontSize: '1.8rem', textDecoration: 'underline' }}>
              Đáp án:
            </p>
          </div>
          <Grid container style={{ marginTop: '1.6rem', marginLeft: '2.8rem' }}>
            {ex.questions
              .filter((item) => item.answer === 'true')
              .map((question) => (
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  key={question.id}
                  style={{
                    marginTop: '1.2rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body">{question.content.split('(')[0]}</Typography>
                  <img
                    src={question.images}
                    alt="question"
                    style={{ maxWidth: '6rem', maxHeight: '6rem', marginLeft: '2rem' }}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default ChooseMultiple;
