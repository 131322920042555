import './Profile.scss';
import { Box, InputLabel, OutlinedInput, FormHelperText, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import userServices from '../../services/userServices';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../redux/userSlice';
import useUser from '../../data/useUser';

export default function Profile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [user, setCurrentUser] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [image, setImage] = useState('');
  const fileInputRef = useRef(null);
  const { data, loading, error, mutate } = useUser(parseInt(id));

  const [inputName, setInputName] = useState({
    value: '',
    message: '',
  });

  const [inputAvatar, setInputAvatar] = useState({
    value: '',
    message: '',
  });

  const validateName = () => {
    if (inputName.value.trim() === '') {
      setInputName({
        ...inputName,
        message: 'Vui lòng điền tên người dùng.',
      });
      return false;
    }
    return true;
  };

  const handleUpdate = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      const validName = validateName();

      if (validName) {
        setIsEditing(false);
        updateUser();
      }
    }
  };

  const updateUser = async () => {
    const inputData = {
      id: parseInt(id, 10),
      name: inputName.value.trim(),
      avatar: inputAvatar.value,
    };
    const result = await userServices.updateById(inputData);
    if (!!result?.message) mutate();
  };

  const handleCancel = () => {
    setIsEditing(false);
    setImage('');
    setInputAvatar({ ...inputAvatar, value: user.avatar });
    setInputName({ value: user.name, message: '' });
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the file input value
    }
  };

  const handleImageChange = (event) => {
    const selectedFile = {};
    selectedFile.file = event.target.files[0];
    selectedFile.preview = URL.createObjectURL(selectedFile.file);
    setImage(selectedFile.preview);
    setInputAvatar({ ...inputAvatar, value: selectedFile.file });
  };

  useEffect(() => {
    return () => {
      image && URL.revokeObjectURL(image.preview);
    };
  }, [image]);

  const userState = useSelector((state) => state.user).user;
  useEffect(() => {
    setCurrentUser(data);
    dispatch(setUser(data));
  }, [data]);

  useEffect(() => {
    if (!!user) {
      setInputName({ ...inputName, value: user.name });
      setInputAvatar({ ...inputAvatar, value: user.avatar });
    }
  }, [user]);

  return (
    <div className="profile-wrapper">
      <h1 className="page-title">Thông tin {user?.roleId === 1 ? 'học' : 'giáo'} viên</h1>
      <div className="form-container">
        <Box className="input">
          <InputLabel required htmlFor={isEditing ? 'name' : ''} className="label">
            Tên người dùng:
          </InputLabel>
          <div>
            <OutlinedInput
              className="outline-input"
              value={isEditing ? inputName.value || '' : user?.name || ''}
              fullWidth
              required
              style={{
                pointerEvents: !isEditing ? 'none' : 'auto',
                color: isEditing ? '#000' : '#00000099',
              }}
              onChange={(event) => {
                setInputName((prevState) => ({
                  ...prevState,
                  value: event.target.value,
                  message: '',
                }));
              }}
              onBlur={validateName}
              id="name"
              type="text"
              error={!!inputName.message}
            />
            {inputName.message && (
              <FormHelperText error id="component-error-text">
                {inputName.message}
              </FormHelperText>
            )}
          </div>
        </Box>

        <Box className="input">
          <InputLabel required className="label">
            Email:
          </InputLabel>
          <div>
            <OutlinedInput
              className="outline-input"
              value={user?.email || ''}
              fullWidth
              required
              style={{
                pointerEvents: 'none',
                color: '#00000099',
              }}
              type="text"
            />
          </div>
        </Box>

        <Box className="input">
          <InputLabel required className="label">
            Role:
          </InputLabel>
          <OutlinedInput
            className="outline-input"
            value={user?.Role.name || ''}
            fullWidth
            required
            style={{ pointerEvents: 'none', color: '#00000099' }}
            type="text"
          />
        </Box>

        <Box className="input">
          <InputLabel htmlFor={isEditing ? 'image' : ''} className="label">
            Ảnh đại diện:
          </InputLabel>
          <Box className="avatar-container">
            {isEditing && (
              <OutlinedInput
                className="outline-input"
                style={{
                  fontSize: '1.4rem',
                  padding: '0.4rem 0.2rem',
                  pointerEvents: !isEditing ? 'none' : 'auto',
                  color: isEditing ? '#000' : '#00000099',
                  marginBottom: '1.2rem',
                }}
                name="image"
                id="image"
                type="file"
                inputProps={{
                  accept: 'image/png, image/gif, image/jpeg',
                }}
                onChange={handleImageChange}
                inputRef={fileInputRef}
              />
            )}
            {!!user?.avatar || image !== '' ? (
              <img
                src={isEditing && image !== '' ? image : user?.avatar}
                alt={user?.name}
                style={{
                  objectFit: 'cover',
                  width: '50%',
                  aspectRatio: '1/1',
                }}
              />
            ) : (
              <p
                style={{
                  color: isEditing ? '#000' : '#00000099',
                }}
              >
                Chưa cập nhật ảnh.
              </p>
            )}
          </Box>
        </Box>
        <div style={{ display: 'flex', gap: '2rem', justifyContent: 'end', marginTop: '3.2rem' }}>
          {isEditing && (
            <Button
              variant="contained"
              sx={{
                fontSize: '1.4rem',
                backgroundColor: 'transparent',
                border: '1px solid #f57f17',
                color: '#f57f17',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={handleCancel}
              autoFocus
            >
              Hủy
            </Button>
          )}
          <Button
            variant="contained"
            sx={{
              fontSize: '1.4rem',
              backgroundColor: '#ffeb3b',
              color: '#000',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#fbc02d',
              },
            }}
            onClick={handleUpdate}
            autoFocus
          >
            {isEditing ? 'Lưu' : 'Chỉnh sửa'}
          </Button>
        </div>
      </div>
    </div>
  );
}
