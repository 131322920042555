import * as React from 'react';
import { Box, Stack, Typography, Grid } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import { useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import './Choose1.scss';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';

function Choose1({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = useState(null);
  const [clickedAudio, setClickedAudio] = React.useState(false);
  const [audioKey, setAudioKey] = React.useState(0);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);

  React.useEffect(() => {
    setCurrentAudio(null);
  }, [ex]);

  const handleSelectAnswer = (questionId, answerItem) => {
    if (!isSubmitted) {
      setAnswerList((prevState) => {
        const updatedAnswers = prevState.map((answer) => {
          if (answer.id === questionId) {
            return { id: questionId, answer: answerItem, exerciseType: ex.exerciseType };
          }
          return answer;
        });
        const isNewAnswer = !prevState.some((answer) => answer.id === questionId);
        if (isNewAnswer) {
          updatedAnswers.push({
            id: questionId,
            answer: answerItem,
            exerciseType: ex.exerciseType,
          });
        }
        return updatedAnswers;
      });
    }
  };

  const handlePlayAudio = (record) => {
    const audio = new Audio(record); // Create an audio element with the record URL
    audio.addEventListener('loadedmetadata', () => {
      const durationInSeconds = audio.duration; // Get the duration in seconds
      const durationInMilliseconds = durationInSeconds * 1000; // Convert to milliseconds

      setAudioKey((prevKey) => prevKey + 1);

      setCurrentAudio(record);
      setClickedAudio(true);
      setTimeout(() => {
        setClickedAudio(false);
      }, durationInMilliseconds);
    });
  };

  return (
    <>
      {ex.record && <AudioBox exeAudio={currentAudio} />}

      <div id="choose1-wrapper">
        <Grid container>
          {ex.questions.length > 0 &&
            ex.questions.map((question) => (
              <Grid key={question.id} item className="grid-item" xs={12} md={12} lg={6}>
                <Typography
                  variant="body"
                  className={`ordinal-number ${
                    isSubmitted &&
                    answerList.findIndex((item) => item.questionId === question.id) === -1
                      ? 'not-choose'
                      : ''
                  }`}
                >
                  {isNaN(question.ordinalNumber) === true ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body" fontSize="1.2rem">
                        {`(${question.ordinalNumber.split('(')[1]}`}
                      </Typography>
                      <Typography variant="body">{question.ordinalNumber.split('(')[0]}</Typography>
                    </Box>
                  ) : (
                    question.ordinalNumber + '.'
                  )}
                </Typography>
                <Box
                  className={`audio`}
                  onClick={() => handlePlayAudio(question.record)}
                  style={{ cursor: 'pointer' }}
                >
                  <CampaignIcon
                    className={`speaker ${
                      clickedAudio && currentAudio === question.record ? 'clicked' : ''
                    }`}
                    sx={{ fontSize: '8rem', color: '#0531f2' }}
                  />
                </Box>
                <Stack direction="column" className="option-list">
                  {question.content.split(',').map((item, index) => {
                    let contentList = [];
                    if (item.includes('(')) contentList = item.split('(');
                    return (
                      <Box
                        key={index}
                        className={`option-item ${
                          !isSubmitted
                            ? answerList.some(
                                (answer) => answer.id === question.id && answer.answer === item,
                              )
                              ? 'clicked'
                              : ''
                            : (() => {
                                const answerItem = answerList.find(
                                  (answer) =>
                                    answer.questionId === question.id &&
                                    JSON.parse(answer.answer) === item,
                                );

                                if (answerItem) {
                                  return answerItem.result === '1'
                                    ? 'true'
                                    : answerItem.result === '0'
                                    ? 'false'
                                    : '';
                                }
                                return '';
                              })()
                        }`}
                        onClick={() => handleSelectAnswer(question.id, item)}
                      >
                        {/* {item} */}
                        {contentList.length ? (
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body" fontSize="1.2rem">
                              {`(${contentList[1]}`}
                            </Typography>
                            <Typography variant="body">{contentList[0]}</Typography>
                          </Box>
                        ) : (
                          <Typography variant="body">{item}</Typography>
                        )}
                      </Box>
                    );
                  })}
                </Stack>
              </Grid>
            ))}
        </Grid>
        {currentAudio && (
          <ReactAudioPlayer
            key={audioKey}
            src={currentAudio}
            autoPlay
            controls
            style={{ display: 'none' }}
          />
        )}
      </div>
    </>
  );
}

export default Choose1;
