import * as React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import './SameOrNot.scss';
import AudioBox from '../../../components/Audio/Audio';
import { useSelector } from 'react-redux';
import { OfflinePin } from '@mui/icons-material';

function SameOrNot({ ex, answerList, setAnswerList }) {
  const [currentAudio, setCurrentAudio] = React.useState(ex.record ? ex.record : null);
  const isSubmitted = useSelector((state) => state.userAnswer.isSubmitted);
  React.useEffect(() => {
    setCurrentAudio(ex.record ? ex.record : null);
  }, [ex]);

  const handleQuestionCheckboxChange = (event) => {
    if (!isSubmitted) {
      const { value, checked } = event.target;
      if (!isSubmitted) {
        if (checked) {
          const answersList = [...answerList];
          answersList.push({
            id: parseInt(value),
            exerciseType: ex.exerciseType,
          });
          setAnswerList(answersList);
        } else {
          const preState = [...answerList];
          const filteredAnswer = preState.filter((ans) => ans.id !== parseInt(value));
          setAnswerList(filteredAnswer);
        }
      }
    }
  };

  return (
    <div className="same-or-not-wrapper">
      <div style={{ fontSize: '1.4rem', fontStyle: 'italic' }}>
        *Chọn đáp án mà bạn cho là đúng.
      </div>
      {ex.record && <AudioBox exeAudio={currentAudio} />}
      <Grid container className="exercise-same-or-not" spacing={{ xs: 0, md: 2, lg: 3 }}>
        {ex.questions.length > 0 &&
          ex.exerciseType === 'Same or not' &&
          ex.questions.map((question) => {
            return (
              <Grid item xs={6} md={6} lg={6} key={question.id} className="same-or-not-question">
                <Typography
                  variant="body"
                  marginRight="0.8rem"
                >{`(${question.ordinalNumber})`}</Typography>

                <Box
                  className={`question-key ${
                    isSubmitted &&
                    answerList.some(
                      (answer) => question.id === answer.questionId && answer.result === '0',
                    )
                      ? 'false'
                      : answerList.some(
                          (answer) => question.id === answer.questionId && answer.result === '1',
                        )
                      ? 'true'
                      : ''
                  }`}
                >
                  <input
                    id={question.id}
                    className="checkbox"
                    type="checkbox"
                    value={question.id}
                    checked={
                      isSubmitted
                        ? answerList.findIndex((ans) => ans.questionId === question.id) > -1
                        : answerList.findIndex((ans) => ans.id === question.id) > -1
                    }
                    onChange={(event) => handleQuestionCheckboxChange(event)}
                  />
                  <label htmlFor={question.id}>{question.content}</label>
                </Box>
              </Grid>
            );
          })}
      </Grid>
      {/* Answer */}
      {isSubmitted && (
        <div className="result-wrapper">
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem', color: '#00baf2' }}>
            <OfflinePin sx={{ fontSize: '2.4rem' }} />
            <p style={{ fontWeight: 'bold', fontSize: '1.8rem', textDecoration: 'underline' }}>
              Đáp án:
            </p>
          </div>
          <Grid container style={{ marginTop: '1.6rem', marginLeft: '2rem' }}>
            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: '2.8rem',
                  marginRight: '1.2rem',
                  color: '#09ef71',
                }}
              />
              {ex.questions
                .filter((ques) => ques.answer === 'same')
                .map((question) => (
                  <Typography
                    key={question.id}
                    variant="body"
                    marginRight="2rem"
                  >{`(${question.ordinalNumber})`}</Typography>
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: 'flex', alignItems: 'center', marginTop: '1.2rem' }}
            >
              <CancelOutlinedIcon
                sx={{
                  fontSize: '2.8rem',
                  marginRight: '1.2rem',
                  color: '#ef0909',
                }}
              />
              {ex.questions
                .filter((ques) => ques.answer === 'not')
                .map((question) => (
                  <Typography
                    key={question.id}
                    variant="body"
                    marginRight="2rem"
                  >{`(${question.ordinalNumber})`}</Typography>
                ))}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default SameOrNot;
