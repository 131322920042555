import { Box, Button, InputLabel, OutlinedInput, Divider } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import userServices from '../../services/userServices';
import './ForgotPassword.scss';

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState({
    value: '',
    message: '',
  });
  const [alert, setAlert] = useState({
    isShow: false,
    status: '',
    message: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { email: email.value };
    const response = await userServices.forgotPassword(data);

    if (!!response?.message) {
      setAlert({
        isShow: true,
        status: 'success',
        message: 'Mật khẩu mới đã được gửi vào email của bạn.',
      });
      setTimeout(() => {
        setAlert({
          isShow: false,
          status: '',
          message: '',
        });
      }, 3000);
    } else {
      setAlert({
        isShow: true,
        status: 'error',
        message:
          response.error === 'Email not found!'
            ? 'Email chưa đăng ký tài khoản.'
            : response.error === 'This user is registered with Google account.'
            ? 'Tài khoản được đăng ký bằng Google account. Vui lòng đăng nhập bằng Google account của bạn.'
            : 'Đã có vấn đề xảy ra, vui lòng thử lại.',
      });
      setTimeout(() => {
        setAlert({
          isShow: false,
          status: '',
          message: '',
        });
      }, 3000);
    }
  };

  return (
    <Box id="forgot-password-wrapper">
      <h1 style={{ fontSize: '3.2rem', color: '#000' }}>Quên mật khẩu</h1>

      <Box component="form" onSubmit={handleSubmit} className="form-wrapper">
        <InputLabel required sx={{ fontSize: '1.6rem', mb: 1 }} htmlFor="email">
          Địa chỉ email:{' '}
        </InputLabel>
        <OutlinedInput
          placeholder="Nhập địa chỉ email để nhận mật khẩu mới"
          required
          fullWidth
          id="email"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(e) => setEmail({ ...email, value: e.target.value })}
        />

        {alert.isShow && (
          <Alert severity={alert.status} className="alert">
            {alert.message}
          </Alert>
        )}

        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2, backgroundColor: '#fdd835', color: '#000', fontSize: '1.6rem' }}
        >
          Gửi
        </Button>
        <Divider style={{ marginBottom: '1.6rem' }}>
          <span style={{ color: '#000' }}>hoặc</span>
        </Divider>
        <div
          style={{ display: 'flex', gap: '2rem', justifyContent: 'center', alignItems: 'center' }}
        >
          <Button
            variant="contained"
            sx={{
              width: '100%',
              fontSize: '1.4rem',
              backgroundColor: 'transparent',
              border: '1px solid #f57f17',
              color: '#f57f17',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={() => navigate('/dang-nhap')}
          >
            Đăng nhập
          </Button>
          <Button
            variant="contained"
            sx={{
              width: '100%',
              fontSize: '1.4rem',
              backgroundColor: 'transparent',
              border: '1px solid #f57f17',
              color: '#f57f17',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={() => navigate('/dang-ky')}
          >
            Đăng ký
          </Button>
        </div>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
